import styled, { css } from 'styled-components'

import { Variant } from '../../../themes'

type InputProps = {
  disabled?: boolean
  filled?: boolean
  rounded?: boolean
  variant?: Variant
}

export const inputStyle = css<InputProps>`
  height: ${props => props.theme.dimensions.inputHeight};
  box-sizing: border-box;
  padding: 1rem;
  font-family: ${props => props.theme.fontFamilies};
  border: 1px solid ${props => props.theme.colors.neutral.base};
  border-radius: ${props => props.theme.dimensions.borderRadius};

  &::placeholder {
    color: ${props => props.theme.colors.neutral.base};
    opacity: 1;
  }

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.primary.base};
    outline: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.primary.base};
  }

  ${props =>
    props.filled &&
    css`
      border-color: ${props.theme.colors.primary.base};
      box-shadow: inset 0 0 0 1px ${props.theme.colors.primary.base};
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.colors.white.base};
      opacity: ${props.theme.opacities.disabled};
      border-color: ${props.theme.colors.secondary.lighter};
      box-shadow: none;

      &:hover {
        border-color: ${props.theme.colors.secondary.lighter};
      }
    `}

  ${props =>
    props.variant &&
    css`
      background-color: ${props.theme.colors[props.variant].lightest};
      border-color: ${props.theme.colors[props.variant].base};
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: ${props.theme.colors[props.variant].base};
        box-shadow: none;
      }
    `}
`

export const InputField = styled.input<InputProps>`
  &&&& {
    ${inputStyle}

    font-size: ${props => props.theme.fontSizes.medium};
    width: 100%;

    ${props =>
      props.rounded &&
      `
        border-radius: 50px;
      `}
  }
`

export const InputContainer = styled.div<{ fluid: boolean; $width: string }>`
  display: inline-block;
  position: relative;
  text-align: left;
  width: ${props => (props.fluid ? '100%' : props.$width)};
`

export const InputFieldContainer = styled.div`
  display: block;
  position: relative;
  width: '100%';

  & > i,
  & > svg {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
`
