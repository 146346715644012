import React, { useState } from 'react'
import { useAsync } from 'react-async'

import FullscreenLoader from 'src/components/base/fullscreenLoader/FullscreenLoader'
import { User } from 'src/types'
import authClient from 'src/utils/auth'

interface AppAuthContext {
  data: {
    user?: User
  }
  forgotPassword: (typeof authClient)['forgotPassword']
  login: (typeof authClient)['login']
  logout: (typeof authClient)['logout']
  registerBuyer: (typeof authClient)['registerBuyer']
  registerSeller: (typeof authClient)['registerSeller']
  resetPassword: (typeof authClient)['resetPassword']
  validatePasswordResetToken: (typeof authClient)['validatePasswordResetToken']
}

const AuthContext = React.createContext<AppAuthContext>({
  data: {},
  forgotPassword: authClient.forgotPassword,
  login: authClient.login,
  logout: authClient.logout,
  registerBuyer: authClient.registerBuyer,
  registerSeller: authClient.registerSeller,
  resetPassword: authClient.resetPassword,
  validatePasswordResetToken: authClient.validatePasswordResetToken,
})

export const AuthProvider = (props: object) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false)

  const {
    data: user,
    error,
    isPending,
    isRejected,
    isSettled,
  } = useAsync<User>({
    promiseFn: authClient.getUser,
  })

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
  }, [isSettled])

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullscreenLoader text={gettext('Checking authorization...')} />
    }
    if (isRejected) {
      return (
        <div>
          <p>Uh oh... There&apos;s a problem. Try refreshing the app.</p>
          <pre>{error?.message ?? 'Unknown error'}</pre>
        </div>
      )
    }
  }

  return (
    <AuthContext.Provider
      value={{
        data: { user },
        forgotPassword: authClient.forgotPassword,
        login: authClient.login,
        logout: authClient.logout,
        registerBuyer: authClient.registerBuyer,
        registerSeller: authClient.registerSeller,
        resetPassword: authClient.resetPassword,
        validatePasswordResetToken: authClient.validatePasswordResetToken,
      }}
      {...props}
    />
  )
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export default AuthProvider
