import styled from 'styled-components'

export const StyledDataLoader = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.colors.neutral.lighter};
  border-radius: 8px;
  margin: 8px 0px;
  max-width: 100%;
  min-height: 12px;
`

export const StyledDataLoaderBar = styled.div`
  display: block;
  position: absolute;
  animation: moving-loader 1.5s linear infinite;
  background-color: ${props => props.theme.colors.neutral.light};
  height: 12px;
  width: 10%;

  @keyframes moving-loader {
    0% {
      left: 0%;
      width: 0%;
    }

    50% {
      width: 30%;
    }

    70% {
      width: 70%;
    }

    80% {
      left: 60%;
    }

    100% {
      left: 100%;
      width: 100%;
    }
  }
`
