import styled from 'styled-components'

export const StyledSearchbarItem = styled.div<{ highlighted: boolean }>`
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light};
  padding: ${props => props.theme.spacings.medium};
  background-color: ${props => props.highlighted && props.theme.colors.neutral.lightest};

  &:last-child {
    border: none;
  }
`
