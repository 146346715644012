import { useHistory } from 'react-router-dom'

import { AppLocale } from '@procsea/common/utils'

const isLocale = (str: string): str is AppLocale => Object.values<string>(AppLocale).includes(str)

export const useLocale = () => {
  const history = useHistory()

  const localeStr = history.location.pathname.split('/')[1]

  if (isLocale(localeStr)) {
    return localeStr
  }

  return AppLocale.EN
}
