import React, { ReactNode, forwardRef } from 'react'

import { Shadow, Size } from '../../../themes/types'
import { StyledCard } from './Card.styles'

export interface CardProps {
  'data-e2e'?: string
  'data-index'?: number
  'data-row-disabled'?: boolean
  'data-row-selected'?: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
  shadow?: Shadow
  spacing?: Size
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      'data-e2e': dataE2e,
      'data-index': dataIndex,
      'data-row-disabled': dataRowDisabled,
      'data-row-selected': dataRowSelected,
      children,
      className,
      onClick,
      shadow,
      spacing,
    },
    forwardedRef
  ) => (
    <StyledCard
      className={className}
      data-e2e={dataE2e}
      data-index={dataIndex}
      data-row-disabled={dataRowDisabled}
      data-row-selected={dataRowSelected}
      onClick={onClick}
      ref={forwardedRef}
      shadow={shadow}
      spacing={spacing}
    >
      {children}
    </StyledCard>
  )
)

export default Card
