import React, { ReactNode, useCallback } from 'react'

import { Tooltip } from '../../../../core'
import { Flex } from '../../../../layout'
import { StyledEditIcon } from './OptionWithEdit.styles'

export interface OptionWithEditProps<Value> {
  children?: ReactNode
  editLabel?: string
  label: string
  onEdit: (value: Value) => void
  value: Value
}

const OptionWithEdit = <Value,>({
  children,
  editLabel,
  label,
  onEdit,
  value,
}: OptionWithEditProps<Value>) => {
  const handleEdit = useCallback(
    (e?: React.MouseEvent<Element, MouseEvent>) => {
      onEdit(value)
      e?.stopPropagation()
    },
    [onEdit, value]
  )

  return (
    <Flex alignment="center">
      {children}

      {editLabel ? (
        <Tooltip tooltip={editLabel} variant="black" placement="auto" trigger="hover">
          <StyledEditIcon
            data-e2e={`select-edit-button--${label}`}
            faStyle="fas"
            name="edit"
            onClick={handleEdit}
            variant="primary"
          />
        </Tooltip>
      ) : (
        <StyledEditIcon
          data-e2e={`select-edit-button--${label}`}
          faStyle="fas"
          name="edit"
          onClick={handleEdit}
          variant="primary"
        />
      )}
    </Flex>
  )
}

export default OptionWithEdit
