import { ActionCreator, AnyAction, Dispatch } from 'redux'

import { checkIfSessionExpired } from 'src/utils'

import * as utils from './responseManager.utils'

export default (
    dispatch: Dispatch,
    actionSuccess: ActionCreator<AnyAction>,
    actionFailed: ActionCreator<AnyAction>,
    ...rest: any[]
  ) =>
  (response: Response | void) => {
    if (response) {
      checkIfSessionExpired(response)
    }

    if (!response || typeof response.json !== 'function') return Promise.resolve({})

    if (utils.hasRequestFailed(response)) {
      return utils.handleJSONResponse(dispatch)(actionFailed)(...rest)(response)
    }

    const contentType = utils.getContentType(response.headers)

    if (contentType === 'application/pdf') {
      return utils.handlePDFResponse(dispatch, actionSuccess, ...rest)(response)
    }
    if (contentType === 'text/csv') {
      return utils.handleCSVResponse(dispatch, actionSuccess, ...rest)(response)
    }
    if (contentType === 'application/vnd.ms-excel') {
      return utils.handleXLSResponse(dispatch, actionSuccess, ...rest)(response)
    }
    if (!contentType) {
      dispatch(actionSuccess(...rest))
      return Promise.resolve()
    }

    return utils.handleJSONResponse(dispatch)(actionSuccess)(...rest)(response)
  }
