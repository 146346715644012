import styled from 'styled-components'

import { Width } from '../../../themes'
import { Text } from '../../core'
import { Stack } from '../../layout'
import { StyledCard } from '../../surfaces/Card/Card.styles'
import { InputContainer } from '../Input/Input.styles'

export const RecentQueriesTitle = styled(Text)`
  display: flex;
  padding: ${props => props.theme.spacings.medium};
  background-color: ${props => props.theme.colors.neutral.lighter};
`

export const StackContainer = styled(Stack)<{ fluid: boolean; width: Width }>`
  position: relative;
  &,
  input,
  ${InputContainer}, ${StyledCard} {
    width: ${props => (props.fluid ? '100%' : props.width)};
  }
`

export const SearchBarContainer = styled.div`
  position: relative;
`

export const SearchbarMenu = styled.div`
  margin-top: ${props => props.theme.spacings.small};
  position: absolute;
  width: 100%;
  z-index: 100;
`
