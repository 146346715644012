import { Dispatch } from 'redux'

import { CreateNotificationArgs, UINotification } from 'src/types'

import makeActionCreator from './makeActionCreator'

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'

export const clearNotification = makeActionCreator(CLEAR_NOTIFICATION, 'notificationId')
export const createNotification = makeActionCreator(CREATE_NOTIFICATION, 'messageType', 'message')

interface CreateNotificationAction {
  type: typeof CREATE_NOTIFICATION
  messageType: UINotification['type']
  message: UINotification['message']
}

export const dispatchCreateNotification =
  (dispatch: Dispatch) =>
  ({ type, message }: CreateNotificationArgs): CreateNotificationAction =>
    dispatch({
      type: CREATE_NOTIFICATION,
      messageType: type,
      message,
    })
