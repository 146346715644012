import styled from 'styled-components'

import { ZIndex } from '../../../../constants/ZIndex'
import { Text } from '../../../core'

export const StyledBurgerMenuTopBar = styled.div`
  background-color: ${props => props.theme.menu.colors.background.base};
  box-shadow: ${props => props.theme.shadows.middle};
  box-sizing: border-box;
  height: ${props => props.theme.menu.dimensions.mobile.topBarHeight};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.MOBILE_TOP_BAR};
`

export const StyledBurgerMenuButton = styled.button`
  background-color: ${props => props.theme.menu.colors.background.base};
  border-radius: 0px;
  border-right: 1px solid ${props => props.theme.menu.colors.neutral.light};
  border: none;
  cursor: pointer;
  height: ${props => props.theme.menu.dimensions.mobile.topBarHeight};
  margin-right: ${props => props.theme.spacings.medium};
  width: ${props => props.theme.menu.dimensions.mobile.topBarHeight};
`

export const StyledTitle = styled(Text)`
  margin: auto;
`
