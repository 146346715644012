import React from 'react'
import { MultiValueProps, Options, components } from 'react-select'

import { Badge, Chip, Tooltip } from '../../../../core'
import { Inline } from '../../../../layout'
import { MyOptionType } from '../../Select.types'

const MoreOptionsTooltip = ({ items }: { items: Options<MyOptionType<unknown>> }) => (
  <Inline spacing="small">
    {items.map(item => (
      <Chip key={item.label} variant="black">
        {item.label}
      </Chip>
    ))}
  </Inline>
)

const MoreSelectedBadge = ({ items }: { items: Options<MyOptionType<unknown>> }) => (
  <Tooltip placement="top" tooltip={<MoreOptionsTooltip items={items} />}>
    <Badge content={`+${items.length}`} size="medium" variant="primary" />
  </Tooltip>
)

const MultiValue = ({ index, getValue, ...props }: MultiValueProps<unknown>) => {
  const maxToShow = props.selectProps.maxVisibleSelectedOptions
  const items = getValue().slice(maxToShow) as Options<MyOptionType<unknown>>

  if (index < maxToShow)
    return <components.MultiValue {...props} index={index} getValue={getValue} />

  if (index === maxToShow) return <MoreSelectedBadge items={items} />

  return null
}

export default MultiValue
