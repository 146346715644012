import styled from 'styled-components'

import { Text } from '../../../core'

export const InputMessage = styled(Text)`
  position: absolute;
  left: 0;
  bottom: -${props => props.theme.spacings.medium};
  white-space: nowrap;
`
