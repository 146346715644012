import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

import { getRequestInit } from 'src/utils'

import responseManager from './responseManager'

/* Register promise that may need to be cancelled in the future */
const fetchControllers = {}

const addController = baseUrl => {
  fetchControllers[baseUrl] = new window.AbortController()
}

const abortPendingFetchRequest = baseUrl => {
  if (fetchControllers[baseUrl]) fetchControllers[baseUrl].abort()
}

/**
 * The Api object
 */
export default {
  get: (url, onlyKeepLast = false) => {
    if (onlyKeepLast) {
      /* Create a new controller that we can store and use later if needed */
      const baseUrl = url.split('?')[0]
      abortPendingFetchRequest(baseUrl)
      addController(baseUrl)
      return fetch(url, {
        ...getRequestInit({ method: 'GET' }),
        signal: fetchControllers[baseUrl].signal,
      })
        .then(res => {
          delete fetchControllers[baseUrl]

          return res
        })
        .catch(error => {
          if (/aborted/.test(error.message)) {
            // eslint-disable-next-line no-console
            console.debug(error.message)
            return Promise.resolve()
          }
          return Promise.reject(error)
        })
    }
    return fetch(url, getRequestInit({ method: 'GET' }))
  },

  post: (url, payload) => fetch(url, getRequestInit({ method: 'POST', payload })),
  responseManager,
}
