import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useFlexLayout, usePagination, useRowSelect, useRowState, useTable } from 'react-table'

import { useEffectAfterMount, useOnScreen } from '../../../hooks'
import { Card } from '../../surfaces'
import DataLoader from './DataLoader/DataLoader'
import { TableProps } from './Table.interfaces'
import * as Styled from './Table.styles'
import { getTableOptions } from './Table.utils'
import Body from './body/Body'
import VirtualizedBody from './body/VirtualizedBody'

const Table = ({
  'data-e2e': dataE2e,
  className,
  columns,
  data,
  dataCount = 0,
  defaultPageSize = 25,
  emptyMessage,
  estimatedSize = 70,
  fetchData,
  getRowId,
  hasHeader = true,
  isLoading = false,
  isVirtualized = false,
  onCellUpdate,
  onClick,
  onSelectedRowChange,
  paddingEnd,
  rowSpacing = 'xsmall',
  skipPageReset = false,
  stickyHeader = false,
  subHeader,
  subHeaderVariant = 'secondary',
}: TableProps) => {
  const loaderRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(loaderRef, `${estimatedSize * 5}px`)
  const [pageCount] = useState(Math.ceil(dataCount / defaultPageSize))
  const options = useMemo(
    () =>
      getTableOptions({
        columns,
        data,
        getRowId,
        onCellUpdate,
        pageCount,
        pageSize: defaultPageSize,
        skipPageReset,
      }),
    [
      JSON.stringify({ data }),
      columns,
      defaultPageSize,
      getRowId,
      onCellUpdate,
      pageCount,
      skipPageReset,
    ]
  )

  const spacing = 'small'

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    /* @ts-ignore */
    setPageSize,
    /* @ts-ignore */
    state: { pageSize, selectedRowIds },
    /* @ts-ignore */
  } = useTable(options, useFlexLayout, usePagination, useRowState, useRowSelect)

  useEffect(() => {
    onSelectedRowChange?.(selectedRowIds)
  }, [selectedRowIds])

  useEffect(() => fetchData?.(pageSize), [pageSize])

  useEffectAfterMount(() => {
    if (pageSize !== defaultPageSize) setPageSize(defaultPageSize)
  }, [dataCount])

  useEffectAfterMount(() => {
    if (loaderRef.current && onScreen && dataCount > defaultPageSize) {
      const newPageSize = pageSize + defaultPageSize
      setPageSize(newPageSize)
    }
  }, [loaderRef, onScreen])

  return (
    <Styled.Table
      {...getTableProps()}
      rowSpacing={rowSpacing}
      className={className}
      data-e2e={dataE2e}
    >
      {hasHeader && (
        <>
          <Styled.CardHeader stickyHeader={stickyHeader} spacing={spacing} ref={headerRef}>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <div {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                {headerGroup.headers.map((column: any, columnIndex) => (
                  <Styled.Head
                    {...column.getHeaderProps()}
                    align={column.align || 'left'}
                    aria-label={column['aria-label']}
                    key={columnIndex}
                  >
                    {column.render('Header')}
                  </Styled.Head>
                ))}
              </div>
            ))}
          </Styled.CardHeader>

          {stickyHeader && (
            <Styled.HeaderHorizontalShadow headerHeight={headerRef.current?.clientHeight} />
          )}
        </>
      )}

      {subHeader && <Styled.SubHeader variant={subHeaderVariant}>{subHeader}</Styled.SubHeader>}

      <Styled.Body {...getTableBodyProps()}>
        {isVirtualized ? (
          <VirtualizedBody
            count={data.length}
            estimatedSize={estimatedSize}
            onClick={onClick}
            paddingEnd={paddingEnd}
            prepareRow={prepareRow}
            rows={rows}
          >
            <div ref={loaderRef}>
              {isLoading && (
                <Card spacing="medium" data-e2e="table-data-loader">
                  <DataLoader />
                </Card>
              )}
            </div>
          </VirtualizedBody>
        ) : (
          <>
            <Body onClick={onClick} prepareRow={prepareRow} rows={rows} />

            <div ref={loaderRef}>
              {isLoading && (
                <Card spacing="medium" data-e2e="table-data-loader">
                  <DataLoader />
                </Card>
              )}
            </div>
          </>
        )}

        {emptyMessage && rows.length === 0 && !isLoading && (
          <Card spacing={spacing}>{emptyMessage}</Card>
        )}
      </Styled.Body>
    </Styled.Table>
  )
}

export default memo(Table)
