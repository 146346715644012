import { AnyAction, Middleware, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from '../reducers/rootReducer'
import INITIAL_ROOT_STATE, { RootState } from './initialStore'

const middlewareList: Middleware[] = [thunk]
const middlewares = applyMiddleware(...middlewareList)

const configureStore = () => {
  const store = createStore<RootState, AnyAction, unknown, unknown>(
    rootReducer,
    INITIAL_ROOT_STATE,
    middlewares
  )
  return store
}

export default configureStore
