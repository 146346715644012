import React from 'react'

import Button from '../../../core/Button/Button'
import { Flex } from '../../../layout'
import {
  StyledMenuHeader,
  StyledMenuHeaderImage,
  StyledMenuHeaderImageContainer,
} from './MenuHeader.styles'

export interface MenuHeaderProps {
  'data-e2e'?: string
  onClick?: () => void
  onClose?: () => void
  src?: string
}

const MenuHeader = ({
  'data-e2e': dataE2e = 'menu-header',
  onClick,
  onClose,
  src,
}: MenuHeaderProps) => (
  <StyledMenuHeader alignment="center" data-e2e={dataE2e}>
    <StyledMenuHeaderImageContainer>
      {!!src && <StyledMenuHeaderImage alt="menu-company-logo" src={src} onClick={onClick} />}
    </StyledMenuHeaderImageContainer>
    {!!onClose && (
      <Button data-e2e="menu-close-button" icon="xmark" text variant="neutral" onClick={onClose} />
    )}
  </StyledMenuHeader>
)

export default MenuHeader
