import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledNavLink = styled(NavLink).attrs(() => ({ activeClassName: 'active' }))`
  position: relative;
  flex: 0 0 auto;
  margin-right: ${props => props.theme.spacings.small};
  color: ${props => props.theme.colors.neutral.base};
  padding: ${props => props.theme.spacingsInsetStretch.xsmall};
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  white-space: nowrap;

  &::last-child {
    margin-right: 0;
  }

  &.active {
    margin-bottom: -2px;
    color: ${props => props.theme.colors.primary.base};
    font-weight: ${props => props.theme.fontWeights.bold};
    border-bottom: 2px solid ${props => props.theme.colors.primary.base};
  }
`
