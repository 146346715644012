interface GetInputVariantArgs {
  errorMessage?: string
  hasError?: boolean
  hasWarning?: boolean
  warningMessage?: string
}

export const getInputVariant = ({
  errorMessage,
  hasError,
  hasWarning,
  warningMessage,
}: GetInputVariantArgs) => {
  if (hasError || !!errorMessage) {
    return 'danger'
  }

  if (hasWarning || !!warningMessage) {
    return 'warning'
  }

  return undefined
}
