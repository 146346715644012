import styled from 'styled-components'

import { FontSize } from '../../../themes'
import { TextProps } from './Text'

export const StyledText = styled.div<
  TextProps & { size: FontSize; $wrap?: boolean; $noWrap?: boolean }
>`
  font-size: ${props => props.theme.fontSizes[props.size]};
  text-transform: ${props => props.fontCase};
  line-height: calc(${props => props.theme.fontSizes[props.size]} * 1.2857);
  color: ${props => {
    if (props.variant) {
      if (props.quiet) {
        return props.theme.colors[props.variant].light
      }
      return props.theme.colors[props.variant].base
    }
    if (props.quiet) {
      return props.theme.colors.neutral.base
    }
    return 'inherit'
  }};
  font-weight: ${props =>
    props.heading || props.bold ? props.theme.fontWeights.bold : props.theme.fontWeights.regular};
  ${props => props.underline && 'text-decoration: underline'};
  ${props => props.italic && 'font-style: italic'};
  ${props =>
    props.ellipsis &&
    `text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;`}
  ${props => props.$wrap && 'white-space: normal;'}
  ${props => props.$noWrap && 'white-space: nowrap;'}
  width: ${props => props.width ?? 'initial'};
`
