import styled from 'styled-components'

import { Shadow, Size } from '../../../themes/types'

type Props = {
  shadow?: Shadow
  spacing?: Size
}

export const StyledCard = styled.div<Props>`
  background-color: white;
  border: 1px solid ${props => props.theme.colors.neutral.lighter};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  box-shadow: ${props => props.shadow && props.theme.shadows[props.shadow]};
  padding: ${props => props.spacing && props.theme.spacingsInset[props.spacing]};
`
