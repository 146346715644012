import React, { SyntheticEvent, forwardRef, memo, useState } from 'react'

import { useEffectAfterMount } from '../../../hooks'
import { Width } from '../../../themes'
import { Icon, IconName } from '../../core'
import Label from '../Label/Label'
import { getInputVariant } from '../form.utils'
import { FormOnChangeParams, InputProps } from '../interfaces'
import { InputContainer, InputField, InputFieldContainer } from './Input.styles'
import Message from './Message/Message'

export interface InputTextProps extends InputProps<string> {
  icon?: IconName
  maxLength?: number
  minLength?: number
  onChange: (params: FormOnChangeParams<string>) => void
  onIconClick?: () => void
  readOnly?: boolean
  rounded?: boolean
  search?: boolean
  width?: Width
}

const Input = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      'data-e2e': dataE2e,
      autofocus,
      className,
      disabled = false,
      errorMessage,
      fluid = false,
      hasError,
      hasWarning,
      helpText,
      icon,
      label,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onKeyDown,
      onIconClick,
      placeholder,
      required = false,
      readOnly,
      rounded,
      search,
      type = 'text',
      value = '',
      warningMessage,
      width = '15em',
      ...rest
    },
    forwardedRef
  ) => {
    const [innerValue, setInnerValue] = useState(value)

    const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

    useEffectAfterMount(() => setInnerValue(value), [value])

    const handleChange = (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement

      setInnerValue(target.value)
      onChange(search ? (event as any) : { name, value: target.value })
    }

    return (
      <InputContainer className={className} fluid={fluid} $width={width}>
        {!!label && (
          <Label
            data-e2e={dataE2e ? `${dataE2e}--label` : undefined}
            helpText={helpText}
            htmlFor={name}
            required={required}
            text={label}
          />
        )}
        <InputFieldContainer>
          <InputField
            autoFocus={autofocus}
            data-e2e={dataE2e}
            disabled={disabled}
            filled={!!innerValue}
            id={name}
            maxLength={maxLength}
            minLength={minLength}
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={forwardedRef}
            rounded={rounded}
            type={type}
            value={innerValue}
            variant={variant}
            {...rest}
          />
          {!!icon && <Icon faStyle="fas" name={icon} onClick={onIconClick} variant="neutral" />}
        </InputFieldContainer>

        {!!errorMessage && (
          <Message data-e2e="input-error-message" type="error" value={errorMessage} />
        )}
        {!!warningMessage && !errorMessage && (
          <Message data-e2e="input-warning-message" type="warning" value={warningMessage} />
        )}
      </InputContainer>
    )
  }
)

export default memo(Input)
