import { get } from 'lodash'
import { ActionCreator, AnyAction, Dispatch } from 'redux'

import { camelCasifyProperties } from './Api.utils'

export const hasRequestFailed = ({ status }: Response) =>
  !(status === 200 || status === 201 || status === 202 || status === 204 || status === 206)

export const getContentType = (headers: Headers) =>
  headers.get('Content-Type') || headers.get('content-type')

export const handleResponseFactory =
  (regex: RegExp) =>
  (dispatch: Dispatch, actionSuccess: ActionCreator<AnyAction>, ...rest: any[]) =>
  (response: Response) => {
    const contentDisposition = response.headers.get('Content-Disposition') || ''
    return response.blob().then(res => {
      dispatch(actionSuccess(res, get(regex.exec(contentDisposition), '[1]'), ...rest))
      return res
    })
  }

export const handlePDFResponse = handleResponseFactory(/filename="(.+\.pdf)"/)

export const handleXLSResponse = handleResponseFactory(/filename="(.+\.(xls|xlsx))"/)

export const handleCSVResponse = handleResponseFactory(/filename="(.+\.csv)"/)

// @TODO: Better error handling as here we are just passing an "error" string to a property of a "success" action.
export const handleJSONResponse =
  (dispatch: Dispatch) =>
  (action: ActionCreator<AnyAction>) =>
  (...rest: any[]) =>
  async (response: Response) => {
    try {
      const body = await response.json()
      const camelCaseBody = camelCasifyProperties(body)
      dispatch(action(camelCaseBody, ...rest))
      return camelCaseBody
    } catch (error) {
      dispatch(action('error', ...rest))
      return undefined
    }
  }
