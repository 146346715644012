import React, { memo } from 'react'

import { useBreakpoints } from '../../../../providers'
import { Badge, Icon, IconName } from '../../../core'
import { IconContainer, StyledNavLink } from './NavTabItem.styles'

export interface NavTabItemProps {
  'data-e2e'?: string
  icon?: IconName
  isActive?: boolean
  link: string
  notificationCount?: number
  onClick?: () => void
  rightLabel?: string
  title: string
}

const NavTabItem = ({
  'data-e2e': dataE2e,
  icon,
  isActive,
  link,
  notificationCount = 0,
  onClick,
  rightLabel,
  title,
}: NavTabItemProps) => {
  const { isMobile } = useBreakpoints()

  return (
    <StyledNavLink
      isActive={typeof isActive === 'boolean' ? () => isActive : undefined}
      onClick={onClick}
      to={link}
      data-e2e={dataE2e}
    >
      {!isMobile && icon ? (
        <IconContainer data-e2e="icon-container">
          <Icon faStyle="far" name={icon} variant="secondary" />
          <Badge
            content={notificationCount}
            data-e2e="notification-count"
            variant="danger"
            floating
          />
        </IconContainer>
      ) : (
        <Badge
          content={notificationCount}
          data-e2e="notification-count"
          variant="danger"
          position="left"
        />
      )}

      {title}
      {!!rightLabel && (
        <Badge content={rightLabel} data-e2e="badge-label" variant="danger" position="right" />
      )}
    </StyledNavLink>
  )
}

export default memo(NavTabItem)
