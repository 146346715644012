import { GenerateQueriesArgs, generateQueries } from 'src/queries/queryGenerator'
import { Dashboard, DashboardList, UserType } from 'src/types'

export const ANALYTICS_DASHBOARDS_ENDPOINT = [UserType.SELLER, 'analytics', 'dashboards'].join('/')

export const SELLER_ANALYTICS_DASHBOARDS_QUERY_KEY = 'sellerAnalyticsDashboards'

export interface GetSellerDashboardsQueryParams {
  sellerCompanyId?: Id | Id[]
  limit?: number
}

const generateDashboardListQueriesConfig: GenerateQueriesArgs<
  DashboardList,
  GetSellerDashboardsQueryParams
> = {
  endpoint: ANALYTICS_DASHBOARDS_ENDPOINT,
  queryKey: SELLER_ANALYTICS_DASHBOARDS_QUERY_KEY,
}

export const { usePaginatedEntitiesQuery: usePaginatedDashboardsQuery } = generateQueries(
  generateDashboardListQueriesConfig
)

const generateDashboardQueriesConfig: GenerateQueriesArgs<
  Dashboard,
  GetSellerDashboardsQueryParams
> = {
  endpoint: ANALYTICS_DASHBOARDS_ENDPOINT,
  queryKey: SELLER_ANALYTICS_DASHBOARDS_QUERY_KEY,
  useSingleEntityQueryArgs: {
    queryOptions: {
      cacheTime: 0,
    },
  },
}

export const { useSingleEntityQuery: useDashboardQuery } = generateQueries(
  generateDashboardQueriesConfig
)
