import React, { ReactNode } from 'react'

import { Alignmentype, Width } from '../../../themes/types'
import { StyledFlex } from './Flex.styles'

export interface FlexProps {
  'data-e2e'?: string
  alignment?: Alignmentype
  /** @deprecated use alignment prop instead */
  center?: boolean
  children?: ReactNode
  className?: string
  fluid?: boolean
  gap?: Width
  wrap?: boolean
}

const Flex = ({
  'data-e2e': dataE2e,
  alignment = 'baseline',
  center = false,
  children,
  className,
  fluid = false,
  gap,
  wrap = false,
}: FlexProps) => (
  <StyledFlex
    alignment={alignment}
    data-e2e={dataE2e}
    center={center}
    className={className}
    fluid={fluid}
    gap={gap}
    $wrap={wrap}
  >
    {children}
  </StyledFlex>
)

export default Flex
