import { DebouncedFunc, debounce } from 'lodash'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormatOptionLabelMeta, OnChangeValue, Props } from 'react-select'
import { AsyncProps } from 'react-select/async'

import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { isNotEmpty } from '../Select/Select'
import { MainContainer, StyledInlineLabel, StyledSelectContainer } from '../Select/Select.styles'
import { MyOptionType, SelectProps } from '../Select/Select.types'
import {
  DropdownIndicator,
  Menu,
  MenuList,
  MultiValue,
  Option,
  OptionWithEdit,
} from '../Select/SelectComponents'
import { getInputVariant } from '../form.utils'
import { StyledAsyncSelect } from './AsyncSelect.styles'

export interface AsyncSelectProps<Value, IsMulti extends boolean>
  extends Omit<SelectProps<Value, IsMulti>, 'options' | 'value' | 'defaultValue' | 'searchable'> {
  debounceValue?: number
  cacheOptions?: AsyncProps<
    MyOptionType<Value>,
    IsMulti,
    { options: MyOptionType<Value>[] }
  >['cacheOptions']
  defaultValue?: Props<MyOptionType<Value>>['defaultValue']
  defaultOptions?: AsyncProps<
    MyOptionType<Value>,
    IsMulti,
    { options: MyOptionType<Value>[] }
  >['defaultOptions']
  loadOptions: AsyncProps<
    MyOptionType<Value>,
    IsMulti,
    { options: MyOptionType<Value>[] }
  >['loadOptions']
  noOptionsMessage?: Props<MyOptionType<Value>>['noOptionsMessage']
  value?: Props['value']
}

const AsyncSelect = <Value, IsMulti extends boolean>({
  'aria-label': ariaLabel,
  'data-e2e': dataE2e = 'async-select',
  debounceValue = 400,
  cacheOptions = true,
  className,
  clearable = false,
  createLabel,
  defaultOptions = true,
  defaultValue,
  disabled = false,
  editLabel,
  errorMessage,
  fluid = false,
  formatOptionLabel,
  formatValueLabel,
  hasError,
  hasWarning,
  helpText,
  inline = false,
  isLoading = false,
  label,
  loadOptions,
  maxVisibleSelectedOptions = 1,
  minWidth = '15rem',
  multiple = false as IsMulti,
  name,
  noOptionsMessage,
  onChange,
  onCreate,
  onEdit,
  placeholder = '',
  required = false,
  rightPosition = false,
  value,
  warningMessage,
  width,
  wrapOptions = false,
}: AsyncSelectProps<Value, IsMulti>) => {
  const [hasValue, setHasValue] = useState(!!value)
  const debouncedLoadOptionsRef = useRef<DebouncedFunc<() => void>>()

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  const isEditable = typeof onEdit === 'function'

  useEffect(
    () => () => {
      debouncedLoadOptionsRef.current?.cancel()
    },
    []
  )

  useEffect(() => {
    setHasValue(isNotEmpty(value) || isNotEmpty(defaultValue))
  }, [JSON.stringify({ value, defaultValue })])

  const handleChange = (selected: OnChangeValue<MyOptionType<Value>, IsMulti>) => {
    setHasValue(isNotEmpty(selected))

    if (multiple) {
      const newValue: OnChangeValue<Value, true> = (
        selected as OnChangeValue<MyOptionType<Value>, true>
      ).map(option => option.value)
      onChange?.({ name, value: newValue as OnChangeValue<Value, IsMulti> })
    } else {
      const newValue: OnChangeValue<Value, false> =
        selected === null ? null : (selected as MyOptionType<Value>).value
      onChange?.({ name, value: newValue as OnChangeValue<Value, IsMulti> })
    }
  }

  const handleOptionFormatting: (
    option: MyOptionType<Value>,
    labelMeta: FormatOptionLabelMeta<MyOptionType<Value>>
  ) => React.ReactNode = (option, { context }) => {
    if (context === 'menu' && formatOptionLabel) {
      return isEditable ? (
        <OptionWithEdit
          editLabel={editLabel}
          label={option.label}
          onEdit={onEdit as () => void}
          value={option.value}
        >
          {formatOptionLabel(option)}
        </OptionWithEdit>
      ) : (
        formatOptionLabel(option)
      )
    }
    if (context === 'value' && formatValueLabel) {
      return formatValueLabel(option)
    }
    return isEditable && context === 'menu' ? (
      <OptionWithEdit
        editLabel={editLabel}
        label={option.label}
        onEdit={onEdit as () => void}
        value={option.value}
      >
        {option.label}
      </OptionWithEdit>
    ) : (
      option.label
    )
  }

  const handleInputChange = useCallback(() => {
    debouncedLoadOptionsRef.current?.cancel()
  }, [])

  const handleLoadOptions: AsyncProps<
    MyOptionType<Value>,
    IsMulti,
    { options: MyOptionType<Value>[] }
  >['loadOptions'] = useCallback(
    (inputValue: string, callback: any) => {
      const debouncedLoadOptions = debounce(
        () => {
          const loader = loadOptions?.(inputValue, callback)
          if (loader && typeof loader.then === 'function') {
            loader.then(callback).catch(() => callback([]))
          }
        },
        inputValue === '' ? 0 : debounceValue
      )

      debouncedLoadOptions()
      debouncedLoadOptionsRef.current = debouncedLoadOptions
    },
    [debounceValue, loadOptions]
  )

  return (
    <MainContainer
      fluid={fluid}
      data-e2e={dataE2e}
      className={className}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      {!!label &&
        (inline ? (
          <StyledInlineLabel htmlFor={name}>{label}</StyledInlineLabel>
        ) : (
          <Label htmlFor={name} text={label} helpText={helpText} required={required} />
        ))}
      <StyledSelectContainer
        disabled={disabled}
        fluid={fluid}
        inline={inline}
        minWidth={minWidth}
        width={width}
      >
        <StyledAsyncSelect
          cacheOptions={cacheOptions}
          classNamePrefix="Select"
          components={{
            DropdownIndicator,
            Menu,
            MenuList,
            MultiValue,
            Option,
          }}
          createLabel={createLabel}
          defaultOptions={defaultOptions}
          defaultValue={defaultValue}
          disabled={disabled}
          filled={hasValue}
          formatOptionLabel={handleOptionFormatting as () => React.ReactNode}
          hideSelectedOptions={!isEditable && multiple}
          inline={inline}
          inputId={name}
          isClearable={clearable}
          isDisabled={disabled}
          isMulti={multiple}
          isSearchable
          loadOptions={handleLoadOptions}
          loading={isLoading}
          maxVisibleSelectedOptions={maxVisibleSelectedOptions}
          name={name}
          noOptionsMessage={noOptionsMessage}
          onChange={handleChange as () => void}
          onCreate={onCreate}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          rightPosition={rightPosition}
          value={value}
          variant={variant}
          wrapOptions={wrapOptions}
        />
      </StyledSelectContainer>

      {!!errorMessage && (
        <Message data-e2e="async-select-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="async-select-warning-message" type="warning" value={warningMessage} />
      )}
    </MainContainer>
  )
}

export default memo(AsyncSelect) as typeof AsyncSelect
