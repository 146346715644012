import { useState } from 'react'

// Inspired from useLocalStorage

interface UseSessionStorageArgs<T> {
  key: string
  initialValue?: T
  onError?: (error: unknown) => void
}

export const useSessionStorage = <T>({
  initialValue,
  key,
  onError,
}: UseSessionStorageArgs<T>): [T | null, (x: T | null) => void] => {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    try {
      const item = window.sessionStorage.getItem(key)

      return item ? JSON.parse(item) : (initialValue ?? null)
    } catch (error) {
      onError?.(error)
      return initialValue ?? null
    }
  })

  const setValue = (value: T | null) => {
    try {
      setStoredValue(value)

      window.sessionStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      onError?.(error)
    }
  }

  return [storedValue, setValue]
}
