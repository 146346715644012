import styled from 'styled-components'

export const ExpandContainer = styled.div<{
  contentHeight?: number
  fluid?: boolean
  timeout: number
}>`
  overflow: hidden;
  height: 0;
  transition: ${props => `height ${props.timeout}ms`};
  width: ${props => (props.fluid ? '100%' : 'initial')};

  &.exit-active,
  &.exit-done {
    height: 0;
  }

  &.enter-active {
    height: ${props => `${props.contentHeight}px`};
  }

  &.enter-done {
    height: auto;
    overflow: visible;
  }
`
