import React, { PropsWithChildren } from 'react'

import MenuFooter from '../MenuFooter/MenuFooter'
import MenuHeader from '../MenuHeader/MenuHeader'
import { StyledMenu, StyledMenuEntries } from './MenuContent.styles'

export interface MenuContentProps {
  'data-e2e'?: string
  footer?: React.ReactNode
  onClose?: () => void
  onLogoClick?: () => void
  logoSrc?: string
}

const MenuContent = ({
  'data-e2e': dataE2e = 'menu',
  footer,
  onClose,
  onLogoClick,
  logoSrc,
  children,
}: PropsWithChildren<MenuContentProps>) => (
  <StyledMenu data-e2e={dataE2e}>
    <MenuHeader
      data-e2e={`${dataE2e}--header`}
      onClick={onLogoClick}
      onClose={onClose}
      src={logoSrc}
    />
    <StyledMenuEntries spacing="xlarge">{children}</StyledMenuEntries>
    {!!footer && <MenuFooter>{footer}</MenuFooter>}
  </StyledMenu>
)

export default MenuContent
