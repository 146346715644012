import styled from 'styled-components'

import { Variant } from '../../../themes/types'
import { LinkProps } from './Link'

export const StyledLink = styled.a<LinkProps & { variant: Variant }>`
  color: ${props => props.theme.colors[props.variant].base};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors[props.variant].dark};
  }

  &:active {
    color: ${props => props.theme.colors[props.variant].darker};
  }
`
