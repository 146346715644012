import React, { ReactNode, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Variant } from '../../../themes'
import { Icon, IconName } from '../../core'
import Message from '../Input/Message/Message'
import { getInputVariant } from '../form.utils'
import { InputProps } from '../interfaces'
import { CheckboxContainer, CheckboxInput, CheckboxLabel } from './Checkbox.styles'

export const checkboxFormats = ['default', 'outlined', 'toggled'] as const

export type CheckboxFormatType = (typeof checkboxFormats)[number]

export interface CheckboxProps extends InputProps<boolean> {
  'data-e2e'?: string
  checked?: boolean
  className?: string
  content?: ReactNode
  format?: CheckboxFormatType
  icon?: IconName
  iconVariant?: Variant
  indeterminated?: boolean
  name: string
}

const Checkbox = ({
  'data-e2e': dataE2e,
  checked = false,
  className,
  content,
  disabled = false,
  errorMessage,
  format = 'default',
  hasError,
  hasWarning,
  icon,
  iconVariant = 'black',
  indeterminated = false,
  name,
  onChange,
  warningMessage,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked)
  const [isIndeterminated, setIsIndeterminated] = useState(indeterminated)
  const isToggle = useMemo(() => format === 'toggled', [format])

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  useEffect(() => {
    setIsIndeterminated(indeterminated)
  }, [indeterminated])

  const handleChange = useCallback(() => {
    setIsChecked(!isChecked)
    setIsIndeterminated(false)
    onChange?.({ name, value: !isChecked })
  }, [name, onChange, isChecked])

  return (
    <CheckboxContainer
      className={className}
      disabled={disabled}
      format={format}
      indeterminated={isIndeterminated}
      variant={variant}
    >
      <CheckboxInput
        checked={isChecked}
        data-e2e={dataE2e}
        data-checkbox-selected={isChecked}
        disabled={disabled}
        id={name}
        name={name}
        onChange={handleChange}
      />
      <CheckboxLabel hasContent={!!content} htmlFor={name} format={format} variant={variant}>
        {isIndeterminated && !isToggle && <Icon name="minus" />}

        {isChecked && !isToggle && <Icon name="check" />}

        {(!!content || !!icon) && (
          <span>
            {!!icon && <Icon faStyle="fas" name={icon} variant={iconVariant} />}
            {content}
          </span>
        )}
      </CheckboxLabel>

      {!!errorMessage && (
        <Message data-e2e="checkbox-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="checkbox-warning-message" type="warning" value={warningMessage} />
      )}
    </CheckboxContainer>
  )
}

export default memo(Checkbox)
