import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

export interface MainStoreActions {
  setSessionAsExpired: () => void
}

export interface MainStoreState {
  actions: MainStoreActions
  isSessionExpired: boolean
}

export const useMainStore = createWithEqualityFn<MainStoreState>(
  set => ({
    actions: {
      setSessionAsExpired: () => set(() => ({ isSessionExpired: true })),
    },
    isSessionExpired: false,
  }),
  shallow
)
