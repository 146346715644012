import styled from 'styled-components'

type Props = {
  size: string
  flex: boolean
  fluid: boolean
  noGutters: boolean
}

export const StyledContainer = styled.div<Props>`
  display: ${props => (props.flex ? 'flex' : 'block')};
  width: 100%;
  max-width: ${props => (props.fluid ? '100%' : props.size)};
  margin: 0 auto;
  ${props => !props.noGutters && `padding: 0 ${props.theme.spacings.medium}`}
`
