import styled from 'styled-components'

import { Button } from '../../../core'

export const StyledBackButton = styled(Button)`
  min-width: auto;
  & > div {
    padding: 0;
  }
`
export const StyledHeaderTitle = styled.div`
  flex: 1;
`
