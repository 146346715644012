import { tint } from 'polished'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledNavLink = styled(NavLink).attrs(() => ({ activeClassName: 'active' }))`
  position: relative;
  flex: 0 0 auto;
  margin-right: ${props => props.theme.spacings.small};
  color: ${props => props.theme.colors.secondary.base};
  background-color: ${props => tint(0.96, props.theme.colors.secondary.base)};
  padding: ${props => props.theme.spacingsInsetSquish.medium};
  border: 1px solid ${props => tint(0.8, props.theme.colors.secondary.base)};
  border-top-left-radius: ${props => props.theme.dimensions.borderRadius};
  border-top-right-radius: ${props => props.theme.dimensions.borderRadius};
  border-bottom: 1px solid transparent;
  text-decoration: none;
  white-space: nowrap;
  transition: border 0.5s;

  &::last-child {
    margin-right: 0;
  }

  &:hover {
    border: 1px solid ${props => props.theme.colors.primary.base};
    border-bottom: 1px solid transparent;
  }

  &.active {
    color: ${props => props.theme.colors.primary.base};
    font-weight: ${props => props.theme.fontWeights.bold};
    border: 1px solid ${props => props.theme.colors.primary.base};
    border-bottom: 1px solid transparent;
    margin-bottom: -1px;
    background-color: white;
  }
`
export const IconContainer = styled.span`
  font-size: 1.2rem;
  vertical-align: bottom;
  margin-right: 0.5rem;
`
