import styled from 'styled-components'

import Tooltip, { CustomTooltipProps } from '../../../core/Tooltip/Tooltip'
import { StyledMenuEntry } from '../MenuEntry/MenuEntry.styles'

export const StyledContextualMenu = styled(Tooltip)<CustomTooltipProps & { fitContent?: boolean }>`
  border: none;
  border-radius: ${props => props.theme.dimensions.borderRadius};
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  ${props => !props.fitContent && `width: ${props.theme.menu.dimensions.desktop.width}`};
  padding: ${props => props.theme.spacings.small};
  margin-left: ${props => props.theme.spacings.medium};
`

export const StyledButton = styled.div<{ isOpen: boolean }>`
  ${props =>
    props.isOpen &&
    `& ${StyledMenuEntry} {
  background-color: ${props.theme.menu.colors.secondary.lightest};

}`}
`
