export const variants = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'pending',
  'neutral',
  'black',
  'white',
  'foreground',
  'background',
] as const
export type Variant = (typeof variants)[number]

export const sizes = ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge'] as const
export type Size = (typeof sizes)[number]

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}
