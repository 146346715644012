import { SVGProps } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { Variant } from '../../../themes'

interface SvgLoaderProps extends SVGProps<SVGElement> {
  centered: boolean
  variant: Variant
}

interface CircleProps extends SVGProps<SVGCircleElement> {
  still?: boolean
  speed?: number
  variant?: Variant
}

const rotate = keyframes`
  0% { stroke-dashoffset: 306; }
  50% { stroke-dasharray: 40, 134; }
  100% {
    stroke-dasharray: 1, 174;
    stroke-dashoffset: 132;
  }
`

export const SvgLoader = styled.svg<SvgLoaderProps>`
  color: ${props => props.theme.colors[props.variant].base};
  ${props =>
    props.centered &&
    css`
      display: block;
      margin: 0 auto;
    `};
`

export const Circle = styled.circle<CircleProps>`
  ${props =>
    !props.still &&
    !!props.speed &&
    css`
      animation: ${rotate} ${140 / props.speed}s linear infinite;
    `};
  ${props => props.variant && `stroke: ${props.theme.colors[props.variant].light}`};
`
