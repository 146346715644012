import { compact } from 'lodash'
import { useState } from 'react'

import { properCasifyString, snakeCasifyProperties } from '@procsea/common/utils'

import { useUser } from 'src/contexts/userContext'

export type Identify = <T = {}>(id: string, args: T) => void

export type Track = <T = {}>(name: string, properties?: T) => void

interface PageProperties {
  component: 'core'
}

type Page = (category: string, page: string, properties: PageProperties) => void

export interface Analytics {
  identify: Identify
  page: Page
  reset: () => void
  track: Track
}

export interface WindowWithAnalytics extends Window {
  analytics?: Analytics
}

interface UseSegmentReturnValue {
  identify: Identify
  isEnabled: boolean
  page: (category: string, page: string) => void
  reset: () => void
  track: Track
}

export const MOCK_USE_SEGMENT: UseSegmentReturnValue = {
  identify: () => {},
  isEnabled: false,
  page: () => {},
  reset: () => {},
  track: () => {},
}

export const useSegment = (): UseSegmentReturnValue => {
  const { analytics } = window as unknown as WindowWithAnalytics
  const [savedAnalytics] = useState(analytics)
  const user = useUser()

  if (window.DISABLE_TRACKING_FOR_STAFF === 'True' && !(user?.impersonatorIsStaff === false)) {
    ;(window as unknown as WindowWithAnalytics).analytics = MOCK_USE_SEGMENT

    return MOCK_USE_SEGMENT
  }

  ;(window as unknown as WindowWithAnalytics).analytics = savedAnalytics

  const track: Track = (name, properties) =>
    analytics?.track(properCasifyString(name), snakeCasifyProperties(properties))

  const identify: Identify = (id, args) =>
    analytics?.identify(compact([window.USER_ID_PREFIX, id]).join('-'), snakeCasifyProperties(args))

  const reset = () => analytics?.reset()

  const page = (category: string, pageName: string) =>
    analytics?.page(properCasifyString(category), properCasifyString(pageName), {
      component: 'core',
    })

  return { identify, isEnabled: true, page, reset, track }
}
