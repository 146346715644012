import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'

import './NotFound.css'

const NotFound = () => (
  <div className="not-found-container" data-e2e="not-found-page">
    <h2>{gettext('404 - Not Found')}</h2>
    <h3>{gettext('Ooops!')}</h3>
    <p>{gettext("That page doesn't exist or is unavailable")}</p>
    <Button className="not-found-cta" as="a" href="/" primary>
      {gettext('Go Back to Home')}
    </Button>
  </div>
)

export default memo(NotFound)
