import styled from 'styled-components'

import { InlineProps } from './Inline'

export const StyledInline = styled.div<InlineProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  align-items: ${props => props.alignment};
  ${props => props.center && `align-items: center;`}
  flex-wrap: ${props => (props.noWrap ? 'nowrap' : 'wrap')};
  white-space: ${props => (props.noWrap ? 'nowrap' : 'normal')};
  line-height: ${props => props.theme.fontSizes.medium};
  width: ${props => props.fluid && '100%'};

  ${props =>
    !!props.spacing &&
    `
  margin-top: -${props.theme.spacings[props.spacing]};
  margin-right: -${props.noWrap ? 0 : props.theme.spacings[props.spacing]};

  & > * {
    margin-top: ${props.theme.spacings[props.spacing]};
    margin-right: ${props.theme.spacings[props.spacing]};
  }
  `}

  ${props =>
    !!props.noWrap &&
    `& > *:last-child {
    margin-right: 0;
  }`}
`
