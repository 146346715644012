import styled, { css } from 'styled-components'

import { Size, Variant } from '../../../themes/types'
import { Card } from '../../surfaces'
import { StyledCard } from '../../surfaces/Card/Card.styles'
import { AlignDirection } from './Table.interfaces'

type CustomProps = {
  align?: AlignDirection
  bold?: boolean
  rowSpacing?: Size
}

const position = css<CustomProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => {
    if (props.align === 'right') {
      return 'flex-end'
    }
    if (props.align === 'left') {
      return 'flex-start'
    }
    return 'center'
  }};
`

export const Head = styled.div<CustomProps>`
  font-weight: ${props => props.theme.fontWeights.bold};
  white-space: nowrap;
  &:not(:last-child) {
    padding-right: ${props => props.theme.spacings.medium};
  }

  ${position};
`
export const CardHeader = styled(Card)<{ stickyHeader?: boolean }>`
  ${props =>
    props.stickyHeader &&
    ` position:sticky;
      top:0;
      z-index: 3;
  `}
`

export const HeaderHorizontalShadow = styled.div<{ headerHeight?: number }>`
  align-self: stretch;
  background-color: ${props => props.theme.colors.white.base};
  box-shadow: 0 0 10px 1px rgba(50, 50, 50, 0.5);
  clip-path: inset(0 0 -10px 0);
  height: 1px;
  margin-bottom: 12px;
  margin-top: -12px;
  position: sticky;
  top: ${props => `${props.headerHeight ?? 45}px`};
  width: 100%;
  z-index: 2;
`

export const SubHeader = styled.div<{ variant: Variant }>`
  background-color: ${props => props.theme.colors[props.variant].lighter};
  color: ${props => props.theme.colors.secondary.base};
  padding: ${props => props.theme.spacingsInsetSquish.medium};
  font-weight: ${props => props.theme.fontWeights.bold};
`

export const Body = styled.div<{ rowSpacing?: Size }>`
  color: ${props => props.theme.colors.secondary.base};
`

export const Row = styled.div`
  align-items: center;
  & > :not(:last-child) {
    padding-right: ${props => props.theme.spacings.large};
  }
`

export const Cell = styled.div<CustomProps>`
  ${props => props.bold && 'font-weight: bold;'};

  ${position};
`

export const CardRow = styled(Card).attrs(props => ({
  'data-e2e': 'table-row',
  'data-index': props['data-index'],
  'data-row-disabled': props['data-row-disabled'],
  'data-row-selected': props['data-row-selected'],
}))<{
  isEdited: boolean
  opacity?: number
  ref?: (node: Element | null) => void
  variant?: Variant
}>`
  ${props =>
    props.isEdited &&
    `
      background-color: ${props.theme.colors.primary.lighter};
      border-color: ${props.theme.colors.primary.base};
    `}

  opacity: ${props => props.opacity || 1};

  ${props =>
    props.variant &&
    `
      background-color: ${props.theme.colors[props.variant].lighter};
      border-color: ${props.theme.colors[props.variant].base};
    `}
`

export const Table = styled.div<CustomProps>`
  ${props =>
    props.rowSpacing &&
    `
      ${StyledCard}:not(:last-child) {
        margin-bottom: calc(${props.theme.spacings[props.rowSpacing]} - 2px);
      }
    `}
`

export const VirtualContainer = styled('div')<{ height: number }>`
  height: ${props => `${props.height}px`};
  width: '100%';
  position: 'relative';
`

export const VirtualItemsContainer = styled('div')<{ paddingEnd?: number; translateY: number }>`
  position: 'absolute';
  top: 0;
  left: 0;
  width: '100%';
  transform: ${props => `translateY(${props.translateY}px)`};
  padding-bottom: ${props =>
    props.paddingEnd ? `${props.paddingEnd}px` : props.theme.spacings.medium};
`
