type Hotjar = (action: string, tags: string[]) => void

export interface WindowWithHotjar extends Window {
  hj?: Hotjar
}

export const useHotjar = () => {
  const tagRecording = (tags: string[]) => {
    ;(window as WindowWithHotjar).hj?.('tagRecording', tags)
  }

  return { tagRecording }
}
