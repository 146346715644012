import React, { memo } from 'react'

import { Button, Text } from '../../../core'
import { Flex, Inline, Stack } from '../../../layout'
import { BackLink, StyledNavigationTitle } from './NavigationTitle.styles'

export type TitleProps = string | { value: string; link: string; linkTitle: string }
export interface NavigationTitleProps {
  actions?: React.ReactNode
  description?: string | React.ReactNode
  onBackLinkClick?: () => void
  title: TitleProps
}

const NavigationTitle = ({
  actions,
  description,
  onBackLinkClick,
  title,
}: NavigationTitleProps) => {
  if (typeof title === 'string')
    return (
      <Flex>
        <Stack spacing="xxsmall">
          <Text variant="secondary" size="xlarge" heading data-e2e="title">
            {title}
          </Text>

          {!!description && (
            <Text variant="secondary" size="medium" data-e2e="description">
              {description}
            </Text>
          )}
        </Stack>

        {actions}
      </Flex>
    )

  return (
    <StyledNavigationTitle spacing="xxsmall">
      <BackLink to={title.link} onClick={onBackLinkClick}>
        <Button variant="neutral" text icon="arrow-left">
          {title.linkTitle}
        </Button>
      </BackLink>

      <Text variant="secondary" size="xlarge" heading data-e2e="title">
        {title.value}
      </Text>

      {!!description && typeof description === 'string' ? (
        <Text variant="secondary" size="medium" data-e2e="description">
          {description}
        </Text>
      ) : (
        description
      )}
    </StyledNavigationTitle>
  )
}

export default memo(NavigationTitle)
