interface GetSessionStorageValue {
  key: string
  onError?: (error: any) => void
}

export const getSessionStorageValue = <T>({ key, onError }: GetSessionStorageValue): T | null => {
  try {
    const item = window.sessionStorage.getItem(key)

    return item ? JSON.parse(item) : null
  } catch (error) {
    onError?.(error)

    return null
  }
}
