import styled from 'styled-components'

type Props = {
  open: boolean
  transitionDuration?: number
  contentHeight?: number
}

export const StyledCollapse = styled('div')<Props>`
  overflow: hidden;
  transition: ${props => `height ${props.transitionDuration}ms`};
  height: ${props => (props.open ? `${props.contentHeight}px` : '0')};
`
