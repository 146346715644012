import { mapValues } from 'lodash'

import { AdvancedSpacings, Spacings } from '../types'

export const applyAdvancedSpacings: (spacings: Spacings) => AdvancedSpacings = spacings => ({
  spacingsInset: mapValues(spacings.spacings, spacing => `${spacing} ${spacing}`),
  spacingsInsetSquish: mapValues(spacings.spacings, spacing => `calc(0.5 * ${spacing}) ${spacing}`),
  spacingsInsetStretch: mapValues(
    spacings.spacings,
    spacing => `calc(1.5 * ${spacing}) ${spacing}`
  ),
})
