import styled from 'styled-components'

import { ZIndex } from '../../../../../constants/ZIndex'
import { Button } from '../../../../core'

export const StyledCreateMenuHeader = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light};
`

export const StyledCreateButton = styled(Button)`
  & > * {
    padding: 0;
  }
`

export const SelectMenu = styled('div')<{ rightPosition?: boolean }>`
  & .Select__menu {
    width: max-content;
    min-width: 100%;
    position: absolute;
    ${props => props.rightPosition && 'right: 0'};
    z-index: 9000;
    box-shadow: 0 4px 11px hsl(0, 0%, 0%, 0.1);
    border: 1px solid ${props => props.theme.colors.neutral.base};
    border-radius: ${props => props.theme.dimensions.borderRadius};
  }
`
