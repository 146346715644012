import React, { SyntheticEvent, memo, useState } from 'react'

import { useEffectAfterMount } from '../../../hooks'
import { Variant, Width } from '../../../themes'
import { Inline } from '../../layout'
import Message from '../Input/Message/Message'
import { NumericInputType } from '../InputNumberSelector/InputNumberSelector'
import Label from '../Label/Label'
import { SelectProps } from '../Select/Select.types'
import { getInputVariant } from '../form.utils'
import { InputProps } from '../interfaces'
import {
  InputSelectContainer,
  StyledInputNumber,
  StyledSelectUnit,
  StyledSingleOption,
} from './InputSelect.styles'

export interface InputSelectInputProps {
  disabledInput?: boolean
  max?: number
  min?: number
  nameInput?: string
  step?: number | 'any'
}

export interface InputSelectSelectProps<OptionValue> {
  clearable?: boolean
  disabledSelect?: boolean
  inputWidth?: Width
  isSingleOptionReadOnly?: boolean
  nameSelect?: string
  onOptionChange?: SelectProps<OptionValue, false>['onChange']
  options: SelectProps<OptionValue, false>['options']
  selectMinWidth?: SelectProps<OptionValue, false>['minWidth']
  selectWidth?: SelectProps<OptionValue, false>['width']
  selectedOption?: OptionValue
}

export type InputSelectProps<OptionValue> = InputSelectInputProps &
  InputSelectSelectProps<OptionValue> &
  Omit<InputProps<NumericInputType>, 'type' | 'name'>

const InputSelect = <OptionValue,>({
  'data-e2e': dataE2e = 'inputselect',
  autofocus = false,
  className,
  clearable = false,
  disabled = false,
  disabledInput = false,
  disabledSelect = false,
  errorMessage,
  fluid = false,
  hasError = false,
  hasWarning = false,
  helpText,
  inputWidth = '7rem',
  isSingleOptionReadOnly = true,
  label,
  max = 99999,
  min = 0,
  nameInput,
  nameSelect,
  onBlur,
  onChange,
  onKeyDown,
  onOptionChange,
  options,
  placeholder,
  required = false,
  selectedOption,
  selectMinWidth = '0rem',
  selectWidth,
  step = 'any',
  value = '',
  warningMessage,
}: InputSelectProps<OptionValue>) => {
  const [innerValue, setInnerValue] = useState<number | ''>(value)

  const variant = getInputVariant({ errorMessage, hasError, hasWarning, warningMessage })

  useEffectAfterMount(() => setInnerValue(value), [value])

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    const result = parseFloat(target.value)
    const parsedValue = Number.isNaN(result) ? '' : result

    if (+parsedValue >= min && +parsedValue <= max) {
      setInnerValue(parsedValue)
      onChange?.({ name: nameInput, value: parsedValue })
    }
  }

  return (
    <InputSelectContainer className={className} data-e2e={dataE2e}>
      {!!label && <Label text={label} helpText={helpText} required={required} />}

      <Inline alignment="center" noWrap>
        <StyledInputNumber
          autoFocus={autofocus}
          data-e2e={`${dataE2e}--input`}
          disabled={disabled || disabledInput}
          filled={!(innerValue === '')}
          fluid={fluid}
          inputWidth={inputWidth}
          max={max}
          min={min}
          name={nameInput}
          onBlur={onBlur}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          step={step}
          type="number"
          value={innerValue}
          variant={variant}
        />
        {options.length <= 1 && isSingleOptionReadOnly ? (
          <StyledSingleOption
            data-e2e={`${dataE2e}--single-option`}
            disabled={disabled || disabledInput || disabledSelect}
          >
            {options[0]?.label}
          </StyledSingleOption>
        ) : (
          <StyledSelectUnit<OptionValue, false>
            data-e2e={`${dataE2e}--select`}
            disabled={disabled || disabledSelect}
            hasError={hasError || !!errorMessage}
            hasWarning={hasWarning || !!warningMessage}
            options={options}
            onChange={onOptionChange}
            clearable={clearable}
            minWidth={selectMinWidth}
            name={nameSelect}
            value={selectedOption}
            width={selectWidth}
          />
        )}
      </Inline>

      {!!errorMessage && (
        <Message data-e2e={`${dataE2e}--error-message`} type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e={`${dataE2e}--warning-message`} type="warning" value={warningMessage} />
      )}
    </InputSelectContainer>
  )
}

export default memo(InputSelect) as typeof InputSelect
