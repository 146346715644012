import React from 'react'
import { OptionProps, components } from 'react-select'

import Checkbox from '../../../Checkbox/Checkbox'
import { MyOptionType } from '../../Select.types'

const Option = (props: OptionProps) => {
  const { innerProps, isMulti, isSelected, children, data } = props

  return (
    <components.Option
      {...props}
      innerProps={{ ...innerProps, 'data-e2e': 'select-menu-option' } as any}
    >
      {isMulti ? (
        <Checkbox
          checked={isSelected}
          content={children}
          name={(data as MyOptionType<string | number>).value.toString()}
        />
      ) : (
        children
      )}
    </components.Option>
  )
}

export default Option
