import styled from 'styled-components'

import { Text } from '../../../core'
import { menuEntryActiveIndicatorWidth } from '../Menu.constants'

export const StyledMenuSectionTitle = styled(Text)`
  color: ${props => props.theme.menu.colors.neutral.base};
  margin-left: calc(${props => props.theme.spacings.small} + ${menuEntryActiveIndicatorWidth});
  user-select: none;
`
