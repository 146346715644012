import React, { memo } from 'react'

import { Badge } from '../../../core'
import { StyledNavLink } from './SubNavTabItem.styles'

export interface SubNavTabItemProps {
  'data-e2e'?: string
  isActive?: boolean
  link: string
  notificationCount?: number
  onClick?: () => void
  title: string
}

const SubNavTabItem = ({
  'data-e2e': dataE2e,
  isActive,
  link,
  notificationCount,
  onClick,
  title,
}: SubNavTabItemProps) => (
  <StyledNavLink
    data-e2e={dataE2e}
    isActive={typeof isActive === 'boolean' ? () => isActive : undefined}
    onClick={onClick}
    to={link}
  >
    {title}
    <Badge content={notificationCount} variant="neutral" position="right" />
  </StyledNavLink>
)

export default memo(SubNavTabItem)
