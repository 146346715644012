import { API_URL } from 'src/constants/constants'
import Api from 'src/services/Api'
import { camelCasifyProperties } from 'src/services/Api.utils'
import {
  BuyerSignupForm,
  ForgotPasswordForm,
  ResetPasswordField,
  ResetPasswordForm,
  SellerSignupForm,
  User,
} from 'src/types'

interface LoginArgs {
  email: string
  password: string
}

interface ValidatePasswordResetTokenArgs {
  token: string
}

export const USER_ENDPOINT = `${API_URL}/users/me/`

const getUser = async (): Promise<User> => {
  const response = await Api.get(USER_ENDPOINT)
  if (!response) {
    throw new Error('Unknown error')
  }
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const user = await response.json()
  return camelCasifyProperties(user)
}

const login = ({ email, password }: LoginArgs) =>
  Api.post(`${API_URL}/login`, {
    email,
    password,
  })

const logout = () => Api.post(`${API_URL}/logout`)

const registerBuyer = (data: Partial<BuyerSignupForm>) =>
  Api.post(`${API_URL}/registration/buyer/`, data)

const registerSeller = (data: Partial<SellerSignupForm>) =>
  Api.post(`${API_URL}/registration/seller/`, data)

const forgotPassword = (data: ForgotPasswordForm) => Api.post(`${API_URL}/password_reset/`, data)

const validatePasswordResetToken = (data: ValidatePasswordResetTokenArgs) =>
  Api.post(`${API_URL}/password_reset/validate_token/`, data)

const resetPassword = (data: Omit<ResetPasswordForm, ResetPasswordField.PASSWORD_VALIDATE>) =>
  Api.post(`${API_URL}/password_reset/confirm/`, data)

export default {
  forgotPassword,
  getUser,
  login,
  logout,
  registerBuyer,
  registerSeller,
  resetPassword,
  validatePasswordResetToken,
}
