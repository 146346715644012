import React, { memo, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Container } from '../../layout'
import { NavTabItemProps } from '../NavTabs/NavTabItem/NavTabItem'
import NavTabs from '../NavTabs/NavTabs'
import { SubNavTabItemProps } from '../SubNavTabs/SubNavTabItem/SubNavTabItem'
import SubNavTabs from '../SubNavTabs/SubNavTabs'
import { SubNavContainer } from './Navigation.styles'
import NavigationTitle, {
  NavigationTitleProps,
  TitleProps,
} from './NavigationTitle/NavigationTitle'

export const getActiveTab = (tabs: NavTabProps[] = [], pathname: string = '') =>
  tabs.find(tab => pathname.includes(tab.link))

export const getActiveSubTab = (tab: NavTabProps | undefined, pathname: string) =>
  tab?.subNav?.find(nav => pathname.includes(nav.link))

export type SubNavTabProps = SubNavTabItemProps & {
  actions?: NavigationTitleProps['actions']
  description?: NavigationTitleProps['description']
  subtitle?: string
}

export type NavTabProps = NavTabItemProps & {
  description?: NavigationTitleProps['description']
  subNav?: SubNavTabProps[]
  subtitle?: string
  topContent?: React.ReactNode
}

export interface NavigationProps {
  description?: NavigationTitleProps['description']
  onBackLinkClick?: () => void
  onClick?: () => void
  tabs?: NavTabProps[]
  title?: TitleProps
}

const Navigation = ({ tabs, title, description, onClick, onBackLinkClick }: NavigationProps) => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(getActiveTab(tabs, pathname))
  const [activeSubTab, setActiveSubTab] = useState(getActiveSubTab(activeTab, pathname))

  const subtitle = activeSubTab?.subtitle || activeTab?.subtitle || title
  const currentDescription = activeSubTab?.description || activeTab?.description || description

  useEffect(() => {
    const newActiveTab = getActiveTab(tabs, pathname)
    const newActiveSubTab = getActiveSubTab(newActiveTab, pathname)

    if (newActiveTab?.subNav?.length && !newActiveSubTab) {
      history.push(`${newActiveTab.subNav[0].link}${search}`)
    } else {
      setActiveTab(newActiveTab)
      setActiveSubTab(newActiveSubTab)
    }
  }, [pathname])

  return (
    <div>
      {!!tabs && <NavTabs tabs={tabs} onClick={onClick} />}

      <SubNavContainer>
        <Container>
          {activeTab?.topContent}

          {!!activeTab?.subNav && (
            <SubNavTabs hasTitle={!!subtitle} tabs={activeTab.subNav} onClick={onClick} />
          )}

          {!!subtitle && (
            <NavigationTitle
              actions={activeSubTab?.actions}
              description={currentDescription}
              onBackLinkClick={onBackLinkClick}
              title={subtitle}
            />
          )}
        </Container>
      </SubNavContainer>
    </div>
  )
}

export default memo(Navigation)
