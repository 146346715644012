import isPropValid from '@emotion/is-prop-valid'
import React, { ReactNode } from 'react'
import styled, {
  BaseTheme,
  CustomTheme,
  ShouldForwardProp,
  StyleSheetManager,
  ThemeProvider as StyledThemeProvider,
} from 'styled-components'
import { BaseModalBackground, ModalProvider } from 'styled-react-modal'

import { addIcons } from '../components/core/Icon/icons'
import { GlobalStyle } from '../components/layout'
import { applyAdvancedStyling, applyCustomProperties } from './helpers'
import procsea from './procsea'

addIcons()

export interface ThemeProviderProps {
  children?: ReactNode
  theme: BaseTheme | CustomTheme
}

const StyledModalBackground = styled(BaseModalBackground)`
  z-index: 10000;
`

// This implements the default behavior from styled-components v5
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

const ThemeProvider = ({ theme = {}, children }: ThemeProviderProps) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <StyledThemeProvider theme={applyAdvancedStyling(applyCustomProperties(procsea, theme))}>
      <ModalProvider backgroundComponent={StyledModalBackground}>
        <GlobalStyle />
        {children}
      </ModalProvider>
    </StyledThemeProvider>
  </StyleSheetManager>
)

export default ThemeProvider
