export enum QuantityUnitSlug {
  BIG_BAG = 'bigbag',
  BIN = 'bin',
  BOURRICHE = 'bourriche',
  COLIS = 'colis',
  KG = 'kg',
  PIECES = 'pcs',
  SACK = 'sack',
}

// tried to extend QuantityUnitSlug but can't find a solution accepted by tsc
export enum CreditNoteQuantityUnitSlug {
  KG = 'kg',
  PIECES = 'pcs',
  COLIS = 'colis',
  BOURRICHE = 'bourriche',
  PERCENT = 'percent',
  BIG_BAG = 'bigbag',
  BIN = 'bin',
  SACK = 'sack',
}

export enum UnitId {
  KG = 5,
  PIECE = 6,
  PACKAGE = 7,
  BASKET = 8,
  BOX = 9,
  BIG_BAG = 43,
  BIN = 44,
  SACK = 45,
}

export interface UnitAPI {
  id: Id
  name: string
  short_name: string
  slug: QuantityUnitSlug
  is_packaged_unit: boolean
}

export interface Unit {
  id: UnitAPI['id']
  name: UnitAPI['name']
  shortName: UnitAPI['short_name']
  slug: UnitAPI['slug']
  isPackagedUnit: UnitAPI['is_packaged_unit']
}
