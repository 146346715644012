/* istanbul ignore file */
import { init as SentryInit } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'intersection-observer'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'url-search-params-polyfill'
import { registerSW } from 'virtual:pwa-register'

import AppProviders from 'src/contexts'

import '../semantic/dist/semantic.min.css'
import App from './App'
import './index.css'
import configureStore from './store/configureStore'

declare global {
  interface Window {
    APP_ENVIRONMENT?: string
    DISABLE_TRACKING_FOR_STAFF: 'True' | 'False'
    HELP_CENTER_URL?: string
    SENTRY_ENVIRONMENT?: string
    SENTRY_DSN?: string
    SENTRY_SAMPLE_RATE?: string
    SOURCE_VERSION?: string
    PREVIOUS_RELEASE_TAG?: string
    RELEASE_TOGGLE_SHOW_SENTRY_USER_FEEDBACK_WIDGET?: 'True' | 'False'
    USER_ID_PREFIX?: string
    process: any
    SCHEDULER_ORDER_ITEMS_LIMIT?: number
    CUTOFF_TIME_GRACE_PERIOD_MINUTES?: number
    KNOWLEDGE_BASE_BUYER_URL?: string
    KNOWLEDGE_BASE_SELLER_URL?: string
    KNOWLEDGE_BASE_CENTRAL_URL?: string
  }
}

// process.env polyfill
if (!window.process) {
  window.process = { env: {} }
}

if (window.SENTRY_DSN) {
  SentryInit({
    dsn: window.SENTRY_DSN,
    environment: window.SENTRY_ENVIRONMENT,
    release: window.SOURCE_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: window.SENTRY_SAMPLE_RATE
      ? parseInt(window.SENTRY_SAMPLE_RATE, 10)
      : undefined,
    ignoreErrors: [
      '/PushError: permission request declined/',
      '/Non-Error promise rejection captured with/',
      '/failed to fetch/',
      'ChunkLoadError',
      'SecurityError: The operation is insecure',
    ],
  })
}

// Create the store
const store = configureStore()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <AppProviders store={store}>
    <App />
  </AppProviders>
)

registerSW({ immediate: true })
