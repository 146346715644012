import React, { PropsWithChildren, useMemo } from 'react'
import { Redirect, RedirectProps, useLocation, useParams } from 'react-router-dom'

import { useUser } from 'src/contexts/userContext'
import { LocaleParams } from 'src/types'

const RequireAuth = ({ children }: PropsWithChildren<{}>) => {
  const { locale } = useParams<LocaleParams>()
  const location = useLocation()

  const user = useUser()

  const redirectTo: RedirectProps['to'] = useMemo(
    () => ({
      pathname: `/${locale}/accounts/login`,
      search: `?next=${location.pathname}${location.search}`,
      state: { from: location },
    }),
    [JSON.stringify(location), locale]
  )

  return !!user ? children : ((<Redirect to={redirectTo} />) as any)
}

export default RequireAuth
