import { GenerateQueriesArgs, generateQueries } from 'src/queries/queryGenerator'
import { BuyerSubscription } from 'src/types'

export const BUYER_SUBSCRIPTIONS_ENDPOINT = 'buyer_subscriptions'
export const BUYER_SUBSCRIPTIONS_QUERY_KEY = 'buyerSubscriptions'

type Asc = 'company_name' | 'marketplace_name'
type Desc = `-${Asc}`

export type BuyerSubscriptionsOrderBy = Asc | Desc

export interface BuyerSubscriptionsQueryParams {
  canDealWithSellerCompanyId?: Id
  companyId?: Id
  companyName?: string
  marketplaceId?: Id[]
  ordering?: BuyerSubscriptionsOrderBy
}

const generateQueriesConfig: GenerateQueriesArgs<BuyerSubscription, BuyerSubscriptionsQueryParams> =
  {
    endpoint: BUYER_SUBSCRIPTIONS_ENDPOINT,
    queryKey: BUYER_SUBSCRIPTIONS_QUERY_KEY,
  }

export const { useAllEntitiesQuery: useBuyerSubscriptionsQuery } =
  generateQueries(generateQueriesConfig)
