import { useParams } from 'react-router'

import { MarketplaceIdParams } from 'src/types'

const useCurrentMarketplaceId = () => {
  const { marketplaceId: marketplaceIdParam } = useParams<MarketplaceIdParams>()
  const marketplaceId = !!marketplaceIdParam ? parseInt(marketplaceIdParam, 10) : undefined

  return marketplaceId
}

export default useCurrentMarketplaceId
