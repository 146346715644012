import React from 'react'
import { DropdownIndicatorProps, components } from 'react-select'

import { Icon, Loader } from '../../../../core'

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { selectProps } = props

  return (
    <components.DropdownIndicator {...props}>
      {selectProps.loading ? (
        <Loader size="1rem" variant="black" />
      ) : (
        <Icon hasPointer faStyle="fas" name="caret-down" variant="neutral" />
      )}
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
