import { AnyAction } from 'redux'

const makeActionCreator =
  <T extends AnyAction>(type: T['type'], ...argNames: Array<keyof Omit<T, 'type'>>) =>
  (...args: any[]) =>
    argNames.reduce(
      (acc, argName, index) => ({
        ...acc,
        [argName]: args[index],
      }),
      { type }
    )

export default makeActionCreator
