import React, { Children } from 'react'
import { MenuListProps, components } from 'react-select'

import { SelectMenuList } from './MenuList.styles'

export const MenuList = (props: MenuListProps) => {
  const { children, selectProps, isMulti, innerProps } = props
  const arrayChildren = Children.toArray(children)

  const selectedOptions = arrayChildren.filter((childNode: any) => childNode.props.isSelected)
  const nonSelectedOptions = arrayChildren.filter((childNode: any) => !childNode.props.isSelected)
  const childrenToRender = [...selectedOptions, ...nonSelectedOptions]

  return (
    <SelectMenuList wrapOptions={selectProps.wrapOptions} isMulti={isMulti}>
      <components.MenuList
        {...props}
        innerProps={{ ...innerProps, 'data-e2e': 'select-menu-list' } as any}
      >
        {childrenToRender}
      </components.MenuList>
    </SelectMenuList>
  )
}

export default MenuList
