import { ACTION_ERROR } from './constants'

export interface ApiPaginatedEntityResponse<T = any> {
  count: number
  next: string | null
  previous: string | null
  results: T[]
}

export interface NonFieldValidationErrors {
  nonFieldErrors?: string[]
}

export interface ValidationErrors {
  [key: string]: string[]
}

export interface APIErrorResponse {
  code: number
  error: string
  userMessage: string
  validationErrors?: NonFieldValidationErrors & ValidationErrors
}

export type ErrorAction<T> = {
  type: T
  [ACTION_ERROR]: APIErrorResponse
}

export const isErrorResponse = (x: unknown): x is APIErrorResponse =>
  !!(x && (x as APIErrorResponse).error)
