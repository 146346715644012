import React, { createContext, useContext, useEffect, useState } from 'react'

import { Membership } from 'src/types'

interface MembershipContextValue {
  currentMembership: Membership
  setCurrentMembership: (newCurrentMembership: Membership) => void
}

const MembershipContext = createContext<MembershipContextValue | undefined>(undefined)

interface MembershipProviderProps {
  children: React.ReactNode
  membership: Membership
}

export const MembershipProvider = ({ children, membership }: MembershipProviderProps) => {
  const [currentMembership, setCurrentMembership] = useState<Membership>(membership)

  useEffect(() => {
    setCurrentMembership(membership)
  }, [JSON.stringify(membership)])

  return (
    <MembershipContext.Provider
      value={{
        currentMembership,
        setCurrentMembership,
      }}
    >
      {children}
    </MembershipContext.Provider>
  )
}

interface UseMembershipContextArgs<T extends boolean> {
  optional?: T
}

type UseMembershipContextResult<T> = T extends true
  ? MembershipContextValue | undefined
  : MembershipContextValue

export const useMembershipContext = <T extends boolean = false>({
  optional,
}: UseMembershipContextArgs<T> = {}): UseMembershipContextResult<T> => {
  const context = useContext(MembershipContext)
  if (context === undefined && !optional) {
    throw new Error('useMembershipContext must be used within a MembershipProvider')
  }

  return context as MembershipContextValue
}
