import { Locale } from 'date-fns'
import { addMinutes, formatWithOptions, startOfDay } from 'date-fns/fp'
import { de, enGB, fr, it } from 'date-fns/locale'

import { AppLocale } from './enums'

const localeMapper: Record<AppLocale, Locale> = {
  [AppLocale.DE]: de,
  [AppLocale.EN]: enGB,
  [AppLocale.FR]: fr,
  [AppLocale.IT]: it,
}

const formatToLocalizedTime = (locale: AppLocale) =>
  formatWithOptions({ locale: localeMapper[locale] })('p')

export const displayLocalizedTime = (args: { time?: string | null; locale: AppLocale }): string => {
  if (typeof args.time === 'string') {
    const startOfToday = startOfDay(Date.now())

    const [hours, minutes] = args.time.split(':').map(str => parseInt(str, 10))

    return formatToLocalizedTime(args.locale)(addMinutes(hours * 60 + minutes)(startOfToday))
  }

  return '--:--'
}
