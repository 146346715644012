import { addMinutes, format, startOfDay } from 'date-fns/fp'

const formatToTime = format('HH:mm:ss')

export const getTimeFromMinutes = (minutes?: number | null): string | null | undefined => {
  if (typeof minutes === 'number') {
    const startOfToday = startOfDay(Date.now())

    return formatToTime(addMinutes(minutes)(startOfToday))
  }

  return minutes
}
