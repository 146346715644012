import styled from 'styled-components'

import { Stack } from '../../../layout'

export const StyledMenu = styled.div`
  background-color: ${props => props.theme.menu.colors.background.base};
  box-shadow: ${props => props.theme.shadows.middle};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: 100dvh;
  left: 0;
  overflow-y: auto;
  position: sticky;
  top: 0;
  width: ${props => props.theme.menu.dimensions.desktop.width};
  z-index: 20;
  & * {
    box-sizing: border-box;
  }
`

export const StyledMenuEntries = styled(Stack)`
  flex: 1 1 auto;
  margin: 0;
  overflow-y: auto;
  padding: ${props => props.theme.spacings.small};
`
