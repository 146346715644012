import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Variant } from '../../../themes'
import { IconProps } from './Icon'

export const StyledStackedIconWrapper = styled.span`
  margin: 2px;
  padding: 0 ${props => props.theme.spacings.medium};
`

export const StyledIcon = styled(FontAwesomeIcon)<{
  backgroundVariant?: Variant
  variant: Variant
  $hasPointer: boolean
}>`
  --fa-animation-duration: 2s;

  color: ${props => props.theme.colors[props.variant].base};

  ${props =>
    props.backgroundVariant && `background: ${props.theme.colors[props.backgroundVariant].base}`}

  &:hover {
    cursor: ${props => (props.$hasPointer || props.onClick ? 'pointer' : 'inherit')};
  }
`

export const IconBackground = styled.div<Partial<IconProps>>`
  display: flex;
  background-color: ${props =>
    props.backgroundVariant ? props.theme.colors[props.backgroundVariant].base : 'transparent'};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  padding: ${props => props.theme.spacings.xsmall};

  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  }
`
