import Async from 'react-select/async'
import styled from 'styled-components'

import { StyledSelectProps, mixinSelectStyle } from '../Select/Select.styles'

export const StyledAsyncSelect = styled(Async).attrs<StyledSelectProps>(({ disabled }) => ({
  disabled,
}))<StyledSelectProps>`
  ${mixinSelectStyle}
`
