import styled from 'styled-components'

import Tooltip from '../Tooltip/Tooltip'

export const StyledContextMenu = styled(Tooltip)`
  padding: 0;
  border: none;
  border-radius: ${props => props.theme.dimensions.borderRadius};
  max-width: none;
`
