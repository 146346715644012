import React, { memo, useMemo } from 'react'

import { Variant } from '../../../../themes'
import { InputMessage } from './Message.styles'

export type MessageType = 'error' | 'warning'

export interface MessageProps {
  'data-e2e'?: string
  type: MessageType
  value: string
}

const Message = ({ 'data-e2e': dataE2e, type, value }: MessageProps) => {
  const variant: Variant = type === 'error' ? 'danger' : 'warning'

  return (
    <InputMessage data-e2e={dataE2e} size="small" variant={variant}>
      {value}
    </InputMessage>
  )
}

export default memo(Message)
