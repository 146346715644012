import React, { memo } from 'react'

import { Variant } from '../../../themes'
import { Text } from '../../core'
import { StockLabel, StyledStock } from './Stock.styles'

export interface StockProps {
  label?: string
  quantity: number
  unit: string
  variant?: Variant
}

const Stock = ({ label, quantity, unit, variant = 'success' }: StockProps) => (
  <StyledStock variant={variant}>
    {!!label && <StockLabel variant={variant}>{label}:</StockLabel>}

    <Text variant={variant} size="small" data-e2e="product-stock">
      {quantity}&nbsp;{unit}
    </Text>
  </StyledStock>
)

export default memo(Stock)
