import styled, { css } from 'styled-components'

export const NavTabsContent = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${props => props.theme.spacings.small} 0 0;
  border-bottom: 1px solid ${props => props.theme.colors.primary.base};
`

export const StyledNavTabs = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.colors.secondary.lightest};

  margin-top: 0;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`
