import { endsWith, get } from 'lodash'
import { AnyAction } from 'redux'
import { v4 } from 'uuid'

import { ACTION_ERROR, ACTION_FORM_ERROR } from 'src/action-creators/constants'
import { CLEAR_NOTIFICATION, CREATE_NOTIFICATION } from 'src/action-creators/notifications'
import { UINotification } from 'src/types'

export type NotificationsState = UINotification[]

export const INITIAL_NOTIFICATIONS_STATE: NotificationsState = []

export const USER_MESSAGE = 'userMessage'

export const DEFAULT_ERROR_MESSAGE = gettext('An error occurred')

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function notifications(state = INITIAL_NOTIFICATIONS_STATE, action: AnyAction) {
  if (endsWith(action.type, 'FAILED') && get(action, ACTION_ERROR)) {
    return [
      {
        id: v4(),
        type: 'negative',
        message: get(action, [ACTION_ERROR, USER_MESSAGE], DEFAULT_ERROR_MESSAGE),
      },
    ]
  }
  if (endsWith(action.type, 'FAILED') && get(action, ACTION_FORM_ERROR)) {
    return [
      {
        id: v4(),
        type: 'negative',
        message: get(action, [ACTION_FORM_ERROR, USER_MESSAGE], DEFAULT_ERROR_MESSAGE),
      },
    ]
  }

  switch (action.type) {
    case CREATE_NOTIFICATION:
      return [
        {
          id: v4(),
          type: action.messageType,
          message: action.message,
        },
      ]
    case CLEAR_NOTIFICATION:
      return state.filter(notification => notification.id !== action.notificationId)
    default:
      return state
  }
}
