import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { StyledButtonIcon, StyledButtonText } from '../../../core/Button/Button.styles'
import { Stack } from '../../../layout'

export const BackLink = styled(Link)`
  width: fit-content;
  text-decoration: none;

  ${StyledButtonText}, ${StyledButtonIcon} {
    padding: 0 0.7rem 0 0;
  }
`

export const StyledNavigationTitle = styled(Stack)`
  span[data-e2e='title'] {
    min-height: 28px;
  }
`
