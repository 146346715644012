import styled from 'styled-components'

import { FlexProps } from './Flex'

export const StyledFlex = styled.div<FlexProps & { $wrap: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.alignment};
  ${props => props.center && 'align-items: center;'}
  ${props => props.$wrap && 'flex-wrap: wrap;'}
  ${props => props.fluid && 'width: 100%;'}
  ${props => props.gap && `gap: ${props.gap};`}
`
