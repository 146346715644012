import { useEffect } from 'react'

import { useSegment } from 'src/hooks'
import { UserType } from 'src/types'

export const usePageTracking = (category: UserType) => (pageName: string) => {
  const { page } = useSegment()

  useEffect(() => {
    page(category, pageName)
  }, [])
}

export const useBuyerPageTracking = usePageTracking(UserType.BUYER)

export const useSellerPageTracking = usePageTracking(UserType.SELLER)
