import styled from 'styled-components'

import { StyledMenuEntry } from '../MenuEntry/MenuEntry.styles'

export const StyledMenuFooter = styled.div`
  background-color: ${props => props.theme.menu.colors.background.base};
  border-top: 1px solid ${props => props.theme.menu.colors.neutral.light};
  bottom: 0;
  position: sticky;
  height: ${props => props.theme.menu.dimensions.desktop.footerHeight};
  justify-content: center;
  padding: ${props => props.theme.spacings.small};
  width: 100%;
  z-index: 1;

  & ${StyledMenuEntry} {
    height: calc( ${props =>
      `${props.theme.menu.dimensions.desktop.footerHeight} - (${props.theme.spacings.small} * 2))`};
  }
`
