import { shade } from 'polished'
import styled from 'styled-components'

type SelectMenuListProps = { isMulti: boolean; wrapOptions?: boolean }

export const SelectMenuList = styled('div')<SelectMenuListProps>`
  & .Select__menu-list {
    padding: 0;

    &:first-child {
      border-top-left-radius: ${props => props.theme.dimensions.borderRadius};
      border-top-right-radius: ${props => props.theme.dimensions.borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${props => props.theme.dimensions.borderRadius};
      border-bottom-right-radius: ${props => props.theme.dimensions.borderRadius};
    }
  }

  & .Select__option {
    white-space: nowrap;
    cursor: pointer;

    ${props => props.wrapOptions && 'white-space: normal'};
    ${props => props.isMulti && 'padding: 0'};
  }

  & .Select__option--is-focused {
    color: ${props => props.theme.colors.black.base};
    background-color: ${props => props.theme.colors.neutral.lighter};

    &:active {
      background-color: ${props => shade(0.05, props.theme.colors.neutral.lighter)};
    }
  }

  & .Select__option--is-selected {
    color: ${props => props.theme.colors.black.base};
    background-color: ${props => props.theme.colors.primary.lighter};

    &:active {
      background-color: ${props => props.theme.colors.primary.lighter};
    }
  }
`
