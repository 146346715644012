import styled, { css } from 'styled-components'

import { Variant } from '../../../themes'
import { RadioFormatType } from '../RadioGroup/context/RadioGroupContext'

type RadioContainerProps = {
  disabled: boolean
  format: RadioFormatType
  variant?: Variant
}

const RADIO_SIZE = '17px'

export const RadioInput = styled.input.attrs(() => ({ type: 'radio' }))`
  opacity: 0;
  position: absolute;
  z-index: -1;
`

export const RadioLabel = styled.label<{ format: RadioFormatType; variant?: Variant }>`
  position: relative;
  user-select: none;
  display: flex;
  padding: ${props => props.theme.spacings.small};
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  min-height: 17px;

  &,
  &:before {
    box-sizing: initial;
  }

  & > span {
    width: 100%;
    padding: ${props =>
      props.format === 'toggled'
        ? 0
        : `0 ${props.theme.spacings.small} 0 ${props.theme.spacings.xlarge}`};

    & > i,
    & > svg {
      padding-right: ${props => props.theme.spacings.small};
    }
  }

  ${props =>
    props.format !== 'toggled' &&
    css`
      &:before,
      &:after {
        position: absolute;
        display: inline-block;
        border-radius: 50%;
        content: '';
      }

      &:before {
        height: ${RADIO_SIZE};
        width: ${RADIO_SIZE};
        margin-right: ${props.theme.spacings.small};
        border: 1px solid
          ${props.variant
            ? props.theme.colors[props.variant].light
            : props.theme.colors.neutral.base};
      }

      &:after {
        display: none;
        background-color: ${props.theme.colors[props.variant || 'primary'].base};
        height: 11px;
        width: 11px;
        left: 11px;
        top: 10px;
        font-size: 0.8rem;
      }
    `}
`

const disabledStyle = css<{ disabled: boolean; variant?: Variant }>`
  ${props =>
    props.disabled &&
    css`
      ${RadioLabel} {
        cursor: not-allowed;
        opacity: ${props.theme.opacities.disabled};
      }

      ${RadioInput}:focus
      + ${RadioLabel}:before,
        ${RadioLabel}:hover:before,
        &:hover
        > ${RadioLabel} {
        border-color: ${props.variant
          ? props.theme.colors[props.variant].light
          : props.theme.colors.neutral.base};
      }
    `}
`

const outlinedStyle = css<{ format: RadioFormatType; variant?: Variant }>`
  ${props =>
    props.format === 'outlined' &&
    css`
      ${RadioInput}:checked + ${RadioLabel} {
        background-color: ${props.theme.colors[props.variant || 'secondary'].lighter};
      }

      ${RadioInput}:focus + ${RadioLabel} {
        border: 1px solid ${props.theme.colors[props.variant || 'primary'].base};
      }

      ${RadioLabel} {
        border: 1px solid
          ${props.variant
            ? props.theme.colors[props.variant].light
            : props.theme.colors.neutral.base};
        border-radius: ${props.theme.dimensions.borderRadius};

        padding: ${props => props.theme.spacings.medium};

        background-color: ${props.variant
          ? props.theme.colors[props.variant].lighter
          : props.theme.colors.white.base};

        &:before {
          margin-right: ${props => props.theme.spacings.medium};
        }

        &:after {
          left: 18px;
          top: 17px;
        }
      }

      &:hover > ${RadioLabel} {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
      }
    `}
`

const toggledStyle = css<{ format: RadioFormatType; variant?: Variant }>`
  ${props =>
    props.format === 'toggled' &&
    css`
      ${RadioLabel} {
        border: 1px solid
          ${props.variant
            ? props.theme.colors[props.variant].light
            : props.theme.colors.neutral.base};
        border-radius: ${props.theme.dimensions.borderRadius};
        height: ${props => props.theme.dimensions.inputHeight};
        padding: 0 ${props => props.theme.spacings.medium};

        background-color: ${props.variant
          ? props.theme.colors[props.variant].lighter
          : props.theme.colors.white.base};
      }

      ${RadioInput}:checked + ${RadioLabel} {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
        box-shadow: inset 0 0 0 1px ${props.theme.colors[props.variant || 'primary'].base};
        background-color: ${props.theme.colors[props.variant || 'secondary'].lighter};
        z-index: 2;

        & > svg {
          position: absolute;
          height: 10px;
          width: 10px;
          right: 1px;
          top: 1px;
          z-index: 1;
          color: white;
        }

        &:before {
          content: '';
          position: absolute;
          height: ${RADIO_SIZE};
          width: ${RADIO_SIZE};
          right: -3px;
          top: -3px;
          border-radius: ${props.theme.dimensions.borderRadius};
          background-color: ${props.theme.colors[props.variant || 'primary'].base};
        }
      }

      &:hover > ${RadioLabel} {
        border-color: ${props.theme.colors[props.variant || 'primary'].base};
      }
    `}
`

export const RadioContainer = styled.div<RadioContainerProps>`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  ${RadioInput}:checked {
    & + ${RadioLabel}:before {
      border-color: ${props => props.theme.colors[props.variant || 'primary'].base};
      box-shadow: inset 0 0 0 1px ${props => props.theme.colors[props.variant || 'primary'].base};
      background-color: ${props => props.theme.colors[props.variant || 'secondary'].lighter};
    }

    & + ${RadioLabel}:after {
      display: block;
    }
  }

  ${RadioInput}:focus + ${RadioLabel}:before, ${RadioLabel}:hover:before {
    border-color: ${props => props.theme.colors[props.variant || 'primary'].base};
  }

  ${outlinedStyle}
  ${toggledStyle}
  ${disabledStyle}
`
