import { UseMutationResult } from '@tanstack/react-query'

import { ContentType } from 'src/utils'

import { usePostAction } from '../entity'
import { PayloadWithId, UseEntityMutationProps } from '../entity.types'

export interface UseActionArgs<Payload, QueryParams = {}, Response = {}> {
  contentType?: ContentType
  entityId?: Id
  queryOptions?: UseEntityMutationProps<Payload, Response, Payload>['queryOptions']
  queryParams?: QueryParams
}

export interface GenerateActionQueriesArgs<TAction extends string> {
  actions: TAction[]
  endpoint: string
}

export const generateActionQueries = <
  TAction extends string,
  Payload extends PayloadWithId,
  QueryParams = {},
  Response = {},
>({
  actions,
  endpoint,
}: GenerateActionQueriesArgs<TAction>) => {
  type ActionHook = (
    args?: UseActionArgs<Payload, QueryParams, Response>
  ) => UseMutationResult<Payload, Error, Payload, Response>

  type ActionHooks = Record<`use${Capitalize<TAction>}Action`, ActionHook>

  const actionHooks = actions?.reduce<ActionHooks>((hooks, action) => {
    const useAction = ({
      contentType,
      queryOptions,
      queryParams,
    }: UseActionArgs<Payload, QueryParams, Response> = {}) =>
      usePostAction<Payload, QueryParams, Response>({
        action,
        contentType,
        endpoint,
        queryOptions,
        queryParams,
      })

    return {
      ...hooks,
      [`use${action.at(0)?.toUpperCase()}${action.substring(1)}Action`]: useAction,
    }
  }, {} as ActionHooks)

  return actionHooks
}
