import { GetTableOptionsArgs, TableOptions } from './Table.interfaces'

export const getTableOptions = ({
  columns,
  data,
  getRowId,
  onCellUpdate,
  pageCount,
  pageSize,
  skipPageReset,
}: GetTableOptionsArgs) => {
  const hiddenColumns = columns.filter(column => column.isHidden).map(column => column.accessor)

  // use the skipPageReset option to disable page resetting temporarily
  let tableOptions = {
    autoResetPage: !skipPageReset,
    autoResetSelectedRows: !skipPageReset,
  } as TableOptions

  if (onCellUpdate) {
    tableOptions = { ...tableOptions, onCellUpdate }
  }
  if (Number.isInteger(pageCount)) {
    return {
      ...tableOptions,
      autoResetRowState: false,
      columns,
      data,
      getRowId,
      initialState: { hiddenColumns, pageIndex: 0, pageSize },
      manualPagination: true,
      pageCount,
    }
  }

  return {
    ...tableOptions,
    autoResetRowState: false,
    columns,
    data,
    initialState: { hiddenColumns },
  }
}
