import Push from 'push.js'

import { Track } from 'src/hooks/useSegment'

export const NOTIFICATION_PERMISSION_DIALOG_ALLOW_BTN = 'Allow'
export const NOTIFICATION_PERMISSION_DIALOG_BLOCK_BTN = 'Block'

export function requestNotificationPermission(track: Track) {
  return new Promise<void>(resolve => {
    /* Request Web Notification permission if not already refused or accepted */
    if (Push.Permission.get() === Push.Permission.DEFAULT) {
      // Display notification permission request dialog to the user
      track('Common - Notification permission dialog - Displayed', {})
      Push.Permission.request(
        () => {
          track('Common - Notification permission dialog - Button clicked', {
            buttonName: NOTIFICATION_PERMISSION_DIALOG_ALLOW_BTN,
          })
          resolve()
        },
        () => {
          // User blocked notifications
          track('Common - Notification permission dialog - Button clicked', {
            buttonName: NOTIFICATION_PERMISSION_DIALOG_BLOCK_BTN,
          })
          resolve()
        }
      )
    }
  })
}
