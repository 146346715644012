import { QualityType } from '@procsea/design-system'

/**
 * @deprecated
 * This is handled by design-system's theme now.
 */
export enum QualityColor {
  GREEN = 'green',
  LIGHTGREEN = 'lightGreen',
  OLIVE = 'olive',
  AMBER = 'amber',
  ORANGE = 'orange',
}

/**
 * @deprecated
 * Use QualityType from design-system instead
 */
export enum QualityName {
  EXCEPTIONAL = 'E+',
  EXTRA = 'E',
  GOOD = 'A+',
  MEDIUM = 'A',
  LOW = 'B',
  EXCEPTIONAL_EXTRA = 'E+/E',
  EXTRA_GOOD = 'E/A+',
  GOOD_MEDIUM = 'A+/A',
  MEDIUM_LOW = 'A/B',
}

/**
 * @deprecated
 * This is handled by design-system's theme now.
 */
export enum BaseQualityName {
  EXCEPTIONAL = 'E+',
  EXTRA = 'E',
  GOOD = 'A+',
  MEDIUM = 'A',
  LOW = 'B',
}

/**
 * @deprecated
 * This is handled by design-system's theme now.
 */
export enum QualityVerbose {
  EXCEPTIONAL = 'Exceptional',
  EXTRA = 'Extra',
  GOOD = 'Good',
  MEDIUM = 'Medium',
  LOW = 'Low',
  NONE = '',
}

/**
 * @deprecated
 * This is handled by <Quality /> component from design system
 */
export interface Quality {
  id: Id
  color: QualityColor
  name: QualityType
  verbose: QualityVerbose
}

export const qualityTranslationMapper: { [key in QualityType]: string } = {
  'E+': gettext('Exceptional'),
  E: gettext('Extra'),
  'A+': gettext('Good'),
  A: gettext('Medium'),
  B: gettext('Low'),
}
