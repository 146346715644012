import { AppLocale } from '@procsea/common/utils'

import { SpiActionType } from './sellerProductInstance'

export enum UserType {
  ADMIN = 'admin',
  BUYER = 'buyer',
  SELLER = 'seller',
  UNKNOWN = 'unknown',
}

export enum PageType {
  HOME = 'home',
  MARKETPLACE = 'marketplace',
  CREDIT_NOTES = 'creditNotes',
  PAYMENTS = 'payments',
  ORDERS = 'orders',
}

export interface LocationState {
  previousUrl?: string
  shouldScrollToBottom?: boolean
}

export interface LocaleParams {
  locale: AppLocale
}

export interface ActionParams {
  action: SpiActionType
}

export interface IdParams {
  id: string
}

export interface UserTypeParams {
  userType: UserType
}

export interface MarketplaceIdParams {
  marketplaceId: string
}

export interface OrderIdParams {
  orderId: string
}

export interface DashboardHashParams {
  dashboardHash: string
}

export interface ChannelSidParams {
  channelSid?: string
}

export interface InvoiceIdParams {
  invoiceId: string
}

export interface InvoicingDocumentIdParams {
  invoicingDocumentId: string
}

export interface CustomerIdParams {
  customerId: string
}

export interface OrderItemIdParams {
  orderItemId: string
}

export interface SupplierIdParams {
  supplierId: string
}

export interface CustomerCategoryIdParams {
  customerCategoryId: string
}

export interface BuyerCompanyIdParams {
  buyerCompanyId: string
}
export interface InvoicingTypeParams {
  invoicingType: 'invoices' | 'credit-notes'
}

export interface SellerIdParams {
  sellerId: string
}

export interface BuyerCompanyUuidParams {
  buyerCompanyUuid?: string
}

export interface VerticalSlugParams {
  vertical?: string
}
