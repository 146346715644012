import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useHotjar } from '@procsea/common/hooks'
import { Container, EmptyState } from '@procsea/design-system'

const ContainerWithTopMargin = styled(Container)`
  margin-top: ${props => props.theme.spacings.medium};
`

const SimpleErrorBoundaryRenderer = () => {
  const { tagRecording } = useHotjar()

  useEffect(() => {
    tagRecording(['sentry-frontend-error'])
  }, [])

  return (
    <ContainerWithTopMargin>
      <EmptyState
        data-e2e="error-boundary-message"
        icon="meh"
        primaryText={gettext('Something went wrong')}
        secondaryText={gettext(
          'Sorry for the inconvenience, our teams will do their best to solve the problem quickly.'
        )}
      />
    </ContainerWithTopMargin>
  )
}

export default SimpleErrorBoundaryRenderer
