import { escape } from 'lodash'
import React, { forwardRef, memo } from 'react'

import { Icon, IconName } from '../../../core'
import { Inline } from '../../../layout'
import { SearchOption } from '../Searchbar.utils'
import { StyledSearchbarItem } from './SearchbarItem.styles'

export interface SearchbaItemProps {
  highlighted: boolean
  icon?: IconName
  item: SearchOption
}

const SearchbarItem = forwardRef<HTMLInputElement, SearchbaItemProps>(
  ({ highlighted, icon, item, ...rest }, forwardedRef) => (
    <StyledSearchbarItem highlighted={highlighted} ref={forwardedRef} {...rest}>
      <Inline alignment="center" spacing="small">
        {!!icon && <Icon name={icon} />}

        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: item.html || escape(item.name) }} />
      </Inline>
    </StyledSearchbarItem>
  )
)

export default memo(SearchbarItem)
