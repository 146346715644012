import { BuyerOrder, GetOrdersRequest } from 'src/types'

import { GenerateQueriesArgs, generateActionQueries, generateQueries } from '../../queryGenerator'

export const PURCHASE_ORDERS_ENDPOINT = 'purchase_orders'
export const PURCHASE_ORDERS_QUERY_KEY = 'purchaseOrders'

const config: GenerateQueriesArgs<BuyerOrder, GetOrdersRequest> = {
  endpoint: PURCHASE_ORDERS_ENDPOINT,
  queryKey: PURCHASE_ORDERS_QUERY_KEY,
}

export const {
  usePaginatedEntitiesQuery: usePaginatedPurchaseOrdersQuery,
  usePatchEntity: usePatchPurchaseOrder,
} = generateQueries(config)

export const { useApproveAction: useApproveOrderAction } = generateActionQueries({
  endpoint: PURCHASE_ORDERS_ENDPOINT,
  actions: ['approve'],
})
