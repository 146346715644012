import styled from 'styled-components'

import { PageContentProps } from './PageContent'

export const StyledPageContent = styled.div<
  PageContentProps & { isAlongsideBurgerMenu: boolean; isMobile: boolean }
>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  padding-bottom: ${props => props.theme.spacings.medium};

  ${props =>
    props.isAlongsideBurgerMenu &&
    `padding: ${props.theme.menu.dimensions.mobile.topBarHeight} 0 ${props.theme.spacings.medium} 0 !important;
    min-height: 100vh;
      &::-webkit-scrollbar {
    display: none;
  }`}
`
