import React from 'react'

import { StyledDataLoader, StyledDataLoaderBar } from './DataLoader.styles'

const DataLoader = () => (
  <StyledDataLoader>
    <StyledDataLoaderBar />
  </StyledDataLoader>
)

export default DataLoader
