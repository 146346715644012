import { useMemo } from 'react'
import { useTheme } from 'styled-components'

import useWindowSize from './useWindowSize'

export enum Breakpoint {
  IS_LARGE_DESKTOP = 'isLargeDesktop',
  IS_MEDIUM_DESKTOP = 'isMediumDesktop',
  IS_SMALL_DESKTOP = 'isSmallDesktop',
  IS_DESKTOP = 'isDesktop',
  IS_TABLET = 'isTablet',
  IS_MOBILE = 'isMobile',
}

type Breakpoints = {
  [key in Breakpoint]: boolean
}

export type BreakpointsResult = Breakpoints & { activeBreakpoint: Breakpoint }

const useBreakpoints = (): BreakpointsResult => {
  const { breakpoints } = useTheme()
  const { width } = useWindowSize()

  const deviceBreakpoints: Breakpoints = useMemo(
    () => ({
      [Breakpoint.IS_MOBILE]: width < breakpoints.tablet.threshold,
      [Breakpoint.IS_TABLET]:
        width < breakpoints.smallDesktop.threshold && width >= breakpoints.tablet.threshold,
      [Breakpoint.IS_SMALL_DESKTOP]:
        width < breakpoints.mediumDesktop.threshold && width >= breakpoints.smallDesktop.threshold,
      [Breakpoint.IS_MEDIUM_DESKTOP]:
        width < breakpoints.largeDesktop.threshold && width >= breakpoints.mediumDesktop.threshold,
      [Breakpoint.IS_LARGE_DESKTOP]: width >= breakpoints.largeDesktop.threshold,
      [Breakpoint.IS_DESKTOP]: width >= breakpoints.smallDesktop.threshold,
    }),
    [JSON.stringify(breakpoints), width]
  )

  const result: BreakpointsResult = useMemo(
    () => ({
      ...deviceBreakpoints,
      activeBreakpoint: Object.keys(deviceBreakpoints)
        .filter((x): x is Breakpoint => !!x)
        .find(breakpoint => deviceBreakpoints[breakpoint]) as Breakpoint,
    }),
    [JSON.stringify(deviceBreakpoints)]
  )

  return result
}

export default useBreakpoints
