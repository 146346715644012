import styled from 'styled-components'

import { FontSize, Variant } from '../../../themes'

export const StyledQuantity = styled.span<{ size: FontSize; variant: Variant }>`
  color: ${({ theme, variant }) => theme.colors[variant].base};
  font-size: ${({ theme, size }) => theme.fontSizes[size]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-right: ${({ theme }) => theme.spacings.xsmall};
`

export const StyledQuantityContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`
