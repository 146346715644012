import styled from 'styled-components'

export const StyledQuestionMark = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid;
  line-height: 1rem;
  border-color: ${props => props.theme.colors.secondary.light};
  width: ${props => props.theme.fontSizes.medium};
  height: ${props => props.theme.fontSizes.medium};
  font-size: ${props => props.theme.fontSizes.small};
  color: ${props => props.theme.colors.secondary.light};
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
`
