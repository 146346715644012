import styled from 'styled-components'

import { StackProps } from './Stack'

export const StyledStack = styled.div<StackProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  width: ${props => (props.fullWidth ? '100%' : null)};
  flex-direction: column;
  ${props => props.alignment && `align-items: ${props.alignment};`}
  ${props => props.center && `align-items: center;`}

  ${props =>
    props.spacing &&
    `& > *:not(:last-child) {
    margin-bottom: ${props.theme.spacings[props.spacing]};
  }`}
`
