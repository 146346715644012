import { Width } from '../../themes'

interface ReplaceWidthValueAndKeepUnitArgs {
  originalWidth: Width
  newWidthValue: number
}

export const replaceWidthValueAndKeepUnit = ({
  originalWidth,
  newWidthValue,
}: ReplaceWidthValueAndKeepUnitArgs): Width => {
  if (!Number.isFinite(newWidthValue)) {
    throw new Error("'newWidthValue must be a finite number.'")
  }

  return originalWidth.replace(/^-?\d+(\.\d+)?/, newWidthValue.toString()) as Width
}
