import React from 'react'
import styled from 'styled-components'

import { GlobalStyle, Loader, Stack, Text } from '@procsea/design-system'

interface FullscreenLoaderProps {
  text?: string
}

const FullscreenContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

const FullscreenLoader = ({ text }: FullscreenLoaderProps) => (
  <>
    <GlobalStyle styledTheme="light" />
    <FullscreenContainer>
      <Stack center spacing="small">
        <Loader centered size="65px" variant="secondary" data-e2e="fullscreen-loader-spinner" />
        {text && (
          <div>
            <Text data-e2e="fullscreen-loader-text" heading variant="secondary">
              {text}
            </Text>
          </div>
        )}
      </Stack>
    </FullscreenContainer>
  </>
)

export default FullscreenLoader
