import React, { ReactNode } from 'react'

import { Variant } from '../../../themes/types'
import { withTooltip } from '../../../utils'
import Icon, { IconName } from '../Icon/Icon'
import StyledButtonContainer, {
  Sticky,
  StyledButtonIcon,
  StyledButtonText,
  StyledIconContainer,
  Type,
} from './Button.styles'

export interface ButtonProps {
  'aria-label'?: string
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  disabled?: boolean
  fluid?: boolean
  form?: string
  hasInputHeight?: boolean
  icon?: IconName
  iconOutline?: boolean
  onClick?: (event?: React.MouseEvent) => void
  outline?: boolean
  reversed?: boolean
  sticky?: Sticky
  text?: boolean
  type?: Type
  underline?: boolean
  variant: Variant
  title?: string
}

const Button = ({
  'aria-label': ariaLabel,
  'data-e2e': dataE2e,
  children,
  className,
  disabled = false,
  fluid = false,
  form,
  hasInputHeight,
  icon,
  iconOutline = false,
  onClick,
  outline = false,
  reversed = false,
  sticky,
  text = false,
  title,
  type = 'button',
  underline = false,
  variant,
}: ButtonProps) => (
  <StyledButtonContainer
    $reversed={reversed}
    aria-label={ariaLabel}
    className={className}
    data-e2e={dataE2e}
    disabled={disabled}
    fluid={fluid}
    form={form}
    hasChildren={!!children}
    hasInputHeight={hasInputHeight}
    onClick={onClick}
    outline={outline}
    sticky={sticky}
    text={text}
    title={title}
    type={type}
    variant={variant}
  >
    {!!icon && (
      <StyledIconContainer>
        <StyledButtonIcon
          name={icon}
          faStyle={iconOutline ? 'far' : 'fas'}
          iconVariant={variant}
          isOutline={outline || text}
        />
      </StyledIconContainer>
    )}
    {children && <StyledButtonText underline={underline}>{children}</StyledButtonText>}
  </StyledButtonContainer>
)

export default withTooltip(Button)
