import React, { ReactNode } from 'react'

import { Alignmentype, Size } from '../../../themes/types'
import { StyledInline } from './Inline.styles'

export interface InlineProps {
  'data-e2e'?: string
  alignment?: Alignmentype
  /** @deprecated use alignment prop instead */
  center?: boolean
  children?: ReactNode
  className?: string
  fluid?: boolean
  inline?: boolean
  noWrap?: boolean
  spacing?: Size
}

const Inline = ({
  'data-e2e': dataE2e,
  alignment = 'baseline',
  center = false,
  children,
  className,
  fluid = false,
  inline = false,
  noWrap = false,
  spacing,
}: InlineProps) => (
  <div>
    <StyledInline
      alignment={alignment}
      center={center}
      className={className}
      data-e2e={dataE2e}
      fluid={fluid}
      inline={inline}
      noWrap={noWrap}
      spacing={spacing}
    >
      {children}
    </StyledInline>
  </div>
)

export default Inline
