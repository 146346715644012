import React, { memo, useRef, useState } from 'react'

import { useEffectAfterMount } from '../../../hooks'
import Message from '../Input/Message/Message'
import Label from '../Label/Label'
import { InputProps } from '../interfaces'
import {
  InputNumber,
  InputNumberButtonLeft,
  InputNumberButtonRight,
  InputNumberContainer,
  InputNumberLabel,
  InputNumberWrapper,
  StyledInputNumberSelector,
} from './InputNumberSelector.styles'

export type NumericInputType = number | ''

export const isValueValid = (value: NumericInputType, min: number, max: number) => {
  if (value === '') return true

  if (+value < min || +value > max) return false

  return true
}

export const getDecimalLength = (value: number) =>
  Number.isInteger(value) ? 0 : value.toString().split('.')[1].length

export interface InputNumberSelectorProps extends InputProps<NumericInputType> {
  'aria-label'?: string
  'data-e2e'?: string
  max?: number
  min?: number
  step?: number
  subLabel?: string
}

const InputNumberSelector = ({
  'aria-label': ariaLabel,
  'data-e2e': dataE2e,
  disabled = false,
  errorMessage,
  hasError = false,
  helpText,
  label,
  max = 99999,
  min = 0,
  name,
  onChange,
  onKeyDown,
  placeholder,
  required = false,
  step = 1,
  subLabel,
  value = '',
  warningMessage,
}: InputNumberSelectorProps) => {
  const inputEl = useRef<HTMLInputElement>(null)
  const [innerValue, setInnerValue] = useState<NumericInputType>(value)
  const [isValid, setIsValid] = useState(isValueValid(value, min, max))
  const [isFocus, setIsFocus] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  useEffectAfterMount(() => setInnerValue(value), [value])

  useEffectAfterMount(() => {
    setIsValid(isValueValid(innerValue, min, max))
    onChange?.({ name, value: innerValue })
  }, [innerValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event
    const result = parseFloat(target.value)
    const parsedValue = Number.isNaN(result) ? '' : result

    if (+parsedValue >= min && +parsedValue <= max) setInnerValue(parsedValue)
  }

  const handleStepDown = () => {
    if (+innerValue > min) {
      let newValue = innerValue === '' ? 0 : innerValue
      const decimalStep = getDecimalLength(step)

      if (innerValue === '') newValue = 0

      if (newValue > max) {
        newValue = max
      } else if (decimalStep) {
        newValue = parseFloat((newValue - step).toFixed(decimalStep))
      } else {
        newValue -= step
      }

      if (newValue >= min) setInnerValue(newValue)
    }
  }

  const handleStepUp = () => {
    if (+innerValue < max) {
      let newValue = innerValue === '' ? 0 : innerValue
      const decimalStep = getDecimalLength(step)

      if (+innerValue < min) {
        newValue = min
      } else if (decimalStep) {
        newValue = parseFloat((newValue + step).toFixed(decimalStep))
      } else {
        newValue += step
      }

      if (newValue <= max) setInnerValue(newValue)
    }
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocus(false)
    setIsInvalid(!event.target.checkValidity())
  }

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checkValidity()) setIsInvalid(false)
  }

  return (
    <StyledInputNumberSelector>
      {label && <Label text={label} helpText={helpText} htmlFor={name} required={required} />}
      <InputNumberWrapper>
        <InputNumberButtonLeft
          data-e2e="step-down-button"
          disabled={disabled || (innerValue || 0) - step < min}
          onClick={handleStepDown}
        >
          -
        </InputNumberButtonLeft>

        <InputNumberContainer
          isValid={isValid}
          hasError={hasError || isInvalid || !!errorMessage}
          isFocus={isFocus}
          hasSubLabel={!!subLabel}
          disabled={disabled}
          onClick={() => !!inputEl.current && inputEl.current.focus()}
        >
          <InputNumber
            ref={inputEl}
            value={innerValue}
            id={name}
            name={name}
            step={step}
            min={min}
            max={max}
            onChange={handleChange}
            onBlur={handleBlur}
            onInput={handleInput}
            onFocus={() => setIsFocus(true)}
            onKeyDown={onKeyDown}
            disabled={disabled}
            placeholder={placeholder}
            data-e2e={dataE2e}
            aria-label={ariaLabel}
          />
          {subLabel && <InputNumberLabel>{subLabel}</InputNumberLabel>}
        </InputNumberContainer>

        <InputNumberButtonRight
          data-e2e="step-up-button"
          disabled={disabled || (innerValue || 0) + step > max}
          onClick={handleStepUp}
        >
          +
        </InputNumberButtonRight>
      </InputNumberWrapper>

      {!!errorMessage && (
        <Message data-e2e="input-number-error-message" type="error" value={errorMessage} />
      )}
      {!!warningMessage && !errorMessage && (
        <Message data-e2e="input-number-warning-message" type="warning" value={warningMessage} />
      )}
    </StyledInputNumberSelector>
  )
}

export default memo(InputNumberSelector)
