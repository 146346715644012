import { GenerateQueriesArgs, generateQueries } from 'src/queries/queryGenerator'
import { Marketplace } from 'src/types'

export const MARKETPLACES_ENDPOINT = 'marketplaces'
export const MARKETPLACES_QUERY_KEY = 'marketplaces'

const generateQueriesConfig: GenerateQueriesArgs<Marketplace> = {
  endpoint: MARKETPLACES_ENDPOINT,
  queryKey: MARKETPLACES_QUERY_KEY,
}

export const { useAllEntitiesQuery: useMarketplacesQuery } = generateQueries(generateQueriesConfig)
