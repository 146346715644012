import styled from 'styled-components'

import { ZIndex } from '../../../../constants/ZIndex'

export const StyledSlider = styled.div`
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
`

export const StyledSliderBackground = styled.div`
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.SLIDING_PANEL};
  body {
    overflow: hidden;
  }
`
