import React, { memo } from 'react'

import { FontSize, Variant } from '../../../themes/types'
import { StyledBadge } from './Badge.styles'

export type BadgePosition = 'right' | 'left'
type BadgeContent = number | string

export interface BadgeProps {
  className?: string
  content?: BadgeContent
  floating?: boolean
  size?: FontSize
  variant?: Variant
  position?: BadgePosition
  'data-e2e'?: string
}

const canDisplayBadge = (content?: BadgeContent) => {
  if (typeof content === 'number' && content > 0) return true
  if (typeof content === 'string' && content.length > 0) return true

  return false
}

const Badge = ({
  className,
  content,
  variant = 'neutral',
  size = 'small',
  floating = false,
  position,
  'data-e2e': dataE2e,
}: BadgeProps) =>
  canDisplayBadge(content) ? (
    <StyledBadge
      className={className}
      data-e2e={dataE2e}
      floating={floating}
      position={position}
      size={size}
      variant={variant}
    >
      {content}
    </StyledBadge>
  ) : null

export default memo(Badge)
