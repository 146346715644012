import { useEffect, useRef } from 'react'

const useEffectAfterMount = <T>(callback: () => void, deps: Array<T>) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) callback()
    else didMount.current = true
  }, deps)
}

export default useEffectAfterMount
