import React, { memo } from 'react'

import { FontSize, Variant } from '../../../themes'
import { Text } from '../../core'
import { StyledPrice, StyledPriceContainer } from './Price.styles'

export interface PriceProps {
  'data-e2e'?: string
  className?: string
  decimal?: number
  sign?: 'plus' | 'minus'
  size?: FontSize
  unit: string
  value: number | string | null
  variant?: Variant
}

const Price = ({
  'data-e2e': dataE2e = 'price',
  className,
  value,
  unit,
  size = 'medium',
  decimal,
  sign,
  variant = 'black',
}: PriceProps) => (
  <StyledPriceContainer className={className}>
    <StyledPrice size={size} data-e2e={dataE2e} variant={variant}>
      {sign === 'plus' && '+ '}
      {sign === 'minus' && '- '}
      {value === null && '-'}
      {typeof value === 'number' && decimal ? value.toFixed(decimal) : value}
    </StyledPrice>
    <Text quiet variant={variant} data-e2e="price-unit">
      {unit}
    </Text>
  </StyledPriceContainer>
)

export default memo(Price)
