import * as Sentry from '@sentry/react'
import React, { PropsWithChildren } from 'react'

import SimpleErrorBoundaryRenderer from './SimpleErrorBoundaryRenderer'

const ErrorBoundary = ({ children }: PropsWithChildren<{}>) => (
  <Sentry.ErrorBoundary
    fallback={<SimpleErrorBoundaryRenderer />}
    showDialog={window.RELEASE_TOGGLE_SHOW_SENTRY_USER_FEEDBACK_WIDGET === 'True'}
  >
    {children}
  </Sentry.ErrorBoundary>
)

export default ErrorBoundary
