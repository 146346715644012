import React from 'react'

import { Icon } from '../../../core'
import {
  StyledBurgerMenuButton,
  StyledBurgerMenuTopBar,
  StyledTitle,
} from './BurgerMenuTopBar.styles'

interface BurgerMenuTopBarProps {
  'data-e2e'?: string
  onClick: () => void
  title?: string
}

const BurgerMenuTopBar = ({ 'data-e2e': dataE2e, onClick, title }: BurgerMenuTopBarProps) => (
  <StyledBurgerMenuTopBar data-e2e={`${dataE2e}--top-bar`}>
    <StyledBurgerMenuButton data-e2e={`${dataE2e}--toggle-button`} onClick={onClick}>
      <Icon faStyle="fas" hasPointer name="bars" size="lg" variant="neutral" />
    </StyledBurgerMenuButton>
    {!!title && (
      <StyledTitle data-e2e={`${dataE2e}--top-bar-title`} bold size="large" variant="secondary">
        {title}
      </StyledTitle>
    )}
  </StyledBurgerMenuTopBar>
)

export default BurgerMenuTopBar
