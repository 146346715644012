import { UseQueryOptions } from '@tanstack/react-query'

import { useEntitiesQuery } from 'src/queries/entity'
import { SellerUser } from 'src/types'

export const ME_QUERY_KEY = 'me'
export const ME_ENDPOINT = 'sellers/me'

export const useMeQuery = <TData = SellerUser>(
  queryOptions?: UseQueryOptions<SellerUser, Error, TData>
) =>
  useEntitiesQuery<SellerUser, {}, TData>({
    endpoint: ME_ENDPOINT,
    queryKey: ME_QUERY_KEY,
    queryOptions: {
      cacheTime: 1000 * 60 * 120,
      staleTime: 1000 * 60 * 60,
      ...queryOptions,
    },
  })
