import React, { memo } from 'react'

import Tooltip from '../Tooltip/Tooltip'
import { StyledQuestionMark } from './Infotip.styles'

export interface InfotipProps {
  'data-e2e'?: string
  helpText: string
}

const Infotip = ({ 'data-e2e': dataE2e, helpText }: InfotipProps) => (
  <Tooltip data-e2e={`${dataE2e}--tooltip`} placement="top" tooltip={helpText} variant="black">
    <StyledQuestionMark data-e2e={dataE2e}>?</StyledQuestionMark>
  </Tooltip>
)

export default memo(Infotip)
