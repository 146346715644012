import React, { FunctionComponent } from 'react'

import Tooltip from '../../components/core/Tooltip/Tooltip'

export interface WithTooltipProps {
  'data-e2e'?: string
  tooltip?: string
  trigger?: 'hover' | 'click'
}

const withTooltip =
  <T extends {}>(Component: FunctionComponent<T>) =>
  (props: T & WithTooltipProps) => {
    const { 'data-e2e': dataE2e, tooltip, trigger = 'hover', ...rest } = props

    if (tooltip) {
      return (
        <Tooltip
          data-e2e={`${dataE2e}--tooltip`}
          fallbackPlacements={['auto']}
          placement="top"
          tooltip={tooltip}
          trigger={trigger}
          variant="black"
        >
          <Component {...(rest as T)} data-e2e={dataE2e} />
        </Tooltip>
      )
    }

    return <Component {...(rest as T)} data-e2e={dataE2e} />
  }

export default withTooltip
