import React from 'react'

import { QualityType } from '../../../themes/types/qualities.type'
import { StyledQuality } from './Quality.styles'

export interface QualityProps {
  fixedSize?: boolean
  quality: QualityType
}

const Quality = ({ fixedSize, quality }: QualityProps) => (
  <StyledQuality fixedSize={fixedSize} quality={quality} data-e2e="quality">
    {quality}
  </StyledQuality>
)

export default Quality
