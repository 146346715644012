import styled from 'styled-components'

export const IframeContainer = styled.div`
  min-height: 200px;
  position: relative;
  width: 100%;

  & > * {
    height: 100%;
    min-height: inherit;
    width: 100%;
  }
`

export const StyledIframe = styled.iframe<{
  $loading: boolean
}>`
  border: none;
  display: ${props => (props.$loading ? 'none' : 'block')};
`

export const IframeLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`
