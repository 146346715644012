import React, { createContext, useContext } from 'react'

import { useSessionStorage } from '@procsea/common/hooks'

import { ERROR_FAIL_TO_RETRIEVE_CURRENT_MEMBERSHIP_FROM_SESSION_STORAGE } from 'src/constants/constants'

interface SessionStorageContextValue {
  currentMembershipId: Id | null
  setCurrentMembershipId: (currentMembershipId: Id | null) => void
}

const SessionStorageContext = createContext<SessionStorageContextValue | undefined>(undefined)

interface SessionStorageProviderProps {
  children: React.ReactNode
}

export const SessionStorageProvider = ({ children }: SessionStorageProviderProps) => {
  const [currentMembershipId, setCurrentMembershipId] = useSessionStorage<Id>({
    key: 'currentMembershipId',
    onError: () => {
      throw new Error(ERROR_FAIL_TO_RETRIEVE_CURRENT_MEMBERSHIP_FROM_SESSION_STORAGE)
    },
  })

  return (
    <SessionStorageContext.Provider
      value={{
        currentMembershipId,
        setCurrentMembershipId,
      }}
    >
      {children}
    </SessionStorageContext.Provider>
  )
}

export const useSessionStorageContext = () => {
  const context = useContext(SessionStorageContext)
  if (context === undefined) {
    throw new Error('useSessionStorageContext must be used within a SessionStorageProvider')
  }

  return context
}
