import styled from 'styled-components'

export const CheckboxGroupContainer = styled.div`
  position: relative;
  max-width: 100%;
  display: inline-grid;

  & > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacings.xsmall};
  }
`

export const InlineContainer = styled('div')<{ noWrap?: boolean }>`
  ${props =>
    props.noWrap &&
    `
    max-width: 100%;
    overflow-x: auto;
`}
`
