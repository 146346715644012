export interface TagApi {
  id: Id
  name: string
}

export interface BuyerTagAPI extends TagApi {
  buyer_company_id: Id
}

export interface SellerTagApi extends TagApi {
  seller_company_id: Id
}

export interface BuyerTag {
  buyerCompanyId: BuyerTagAPI['buyer_company_id']
  id: TagApi['id']
  name: TagApi['name']
}

export interface SellerTag {
  id: TagApi['id']
  name: TagApi['name']
  sellerCompanyId: SellerTagApi['seller_company_id']
}

export interface BuyerTagsQueryParams {
  buyerCompanyId?: Id | Id[]
}

export interface SellerTagsQueryParams {
  sellerCompanyId?: Id | Id[]
  ordering?: TagApi['name']
}

export interface BuyerTagRequestBody {
  buyerCompanyId: Id
  id?: Id
  name: string
}

export interface SellerTagRequestBody {
  id?: Id
  name: string
  sellerCompanyId: Id
}

export enum TagType {
  COMMERCIAL = 'commercial',
  DELIVERY_TOUR = 'delivery tour',
  GROUP = 'group',
  ROLE = 'role',
  TOUR = 'tour',
}

export const i18nTagType: Record<TagType, string> = {
  [TagType.COMMERCIAL]: gettext('sales rep'),
  [TagType.DELIVERY_TOUR]: gettext('delivery tour'),
  [TagType.GROUP]: gettext('group'),
  [TagType.ROLE]: gettext('role'),
  [TagType.TOUR]: gettext('tour'),
}
